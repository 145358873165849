import {
  ApiOperationResult,
  ApiOperationStatus,
} from "models/api-operation-result.model";

const buildOperationResponse = async <T>(
  response: Response,
  type: "json" | "blob" = "json"
): Promise<ApiOperationResult<T>> => {
  if (response.status === 401) {
    return {
      status: ApiOperationStatus.Unauthorized,
    };
  }

  if (response.status !== 200 && response.status !== 204) {
    return {
      status: ApiOperationStatus.Error,
    };
  }

  if (response.status === 200 && type === "json") {
    let result = await response.json();
    return {
      status: ApiOperationStatus.Success,
      data: result as T,
    };
  }

  if (response.status === 200 && type === "blob") {
    let result = await response.blob();
    return {
      status: ApiOperationStatus.Success,
      data: result as any,
    };
  }

  return {
    status: ApiOperationStatus.Success,
  };
};

const GET = async <T>(
  url: string,
  token?: string
): Promise<ApiOperationResult<T>> => {
  try {
    let headers: any = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    const result = await buildOperationResponse<T>(response);
    return result;
  } catch (error) {
    return {
      status: ApiOperationStatus.Error,
    };
  }
};

const GETBlob = async (
  url: string,
  token: string
): Promise<ApiOperationResult<Blob>> => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await buildOperationResponse<Blob>(response, "blob");
    return result;
  } catch (error) {
    return {
      status: ApiOperationStatus.Error,
    };
  }
};

const updateData = async <TRequest, TResponse>(
  url: string,
  method: "POST" | "PUT",
  token?: string,
  data?: TRequest
): Promise<ApiOperationResult<TResponse>> => {
  const headers: any = { "Content-Type": "application/json" };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request: any = {
    method,
    headers,
  };

  if (data) {
    request.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(url, request);
    return buildOperationResponse<TResponse>(response);
  } catch (error) {
    return {
      status: ApiOperationStatus.Error,
    };
  }
};

const POST = async <TRequest, TResponse>(
  url: string,
  token?: string,
  request?: TRequest
) => updateData<TRequest, TResponse>(url, "POST", token, request);

const PUT = async <TData, TResponse>(
  url: string,
  token?: string,
  data?: TData
) => updateData<TData, TResponse>(url, "PUT", token, data);

const DELETE = async <TData>(url: string, token: string, data?: TData) => {
  const headers: any = { "Content-Type": "application/json" };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request: any = {
    method: "DELETE",
    headers,
  };

  if (data) {
    request.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(url, request);
    return buildOperationResponse(response);
  } catch (error) {
    return {
      status: ApiOperationStatus.Error,
    };
  }
};

export { GET, GETBlob, POST, PUT, DELETE };
