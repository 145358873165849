import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApi } from "modules/store";
import { signIn, storeAuthData } from "../services/auth.service";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { updateLoginStatus } from "../store/session.reducer";
import { LoginStatus } from "models/authentication.model";
import { initializeUserSession } from "modules/user-session-initializer.action";
import { history } from "modules/shared/services/routing/history";

export const loginUser = createAsyncThunk<
  void,
  { login: string; password: string },
  ThunkApi
>("auth/login", async ({ login, password }, { dispatch }) => {
  const authResult = await signIn(login, password);

  if (authResult.status === ApiOperationStatus.Success && authResult.data) {
    storeAuthData(authResult.data);

    const authenticationData = { token: authResult.data.accessToken };
    await dispatch(
      initializeUserSession({
        authenticationData: authenticationData,
        shouldRequestPushNotificationToken: false,
      })
    );

    history.replace("/home");
  } else {
    dispatch(updateLoginStatus(LoginStatus.failed));
  }
});
