import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import "./styles.scss";
import Render from "modules/shared/components/Render/Render";
import { motion } from "framer-motion";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "modules/store";
import Avatar from "modules/shared/components/Avatar/Avatar";
import {
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import {
  generateDifficultyFeedback,
  generateTimeFeedback,
  getGeneralFeedback,
  isTimeSpentAcceptable,
  levelToNumber,
  levelToText,
} from "modules/challenge/services/result-handler.service";
import Button, { BackButton } from "modules/shared/components/Button/Button";
import { openSkillDetails } from "modules/shared/actions/navigation.action";
import MarkdownReader from "modules/shared/components/MarkdownReader/MarkdownReader";
import { FiAward } from "react-icons/fi";

interface Params {
  learnerChallengeId: string;
  viewMode: string;
}

const ChallengeResults: React.FC = () => {
  const history = useHistory();

  const { learnerChallengeId, viewMode } = useParams<Params>();
  const isInViewMode = viewMode === "true";

  const { data: learnerInformation } = useAppSelector(
    (state) => state.learnerInformation
  );

  const { learnerChallenges } = useAppSelector((state) => state.challenge);
  const learnerChallenge = learnerChallenges.find(
    (lc) => lc.id === +learnerChallengeId
  );

  const skillCode = learnerChallenge?.skillCode;
  const challengeCode = learnerChallenge?.challengeCode;

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);
  const challenge = skill?.challenges?.find((c) => c.code === challengeCode);

  const dispatch = useAppDispatch();

  if (!skill || !challenge || !learnerChallenge?.feedback) return null;

  const goToSkill = () => {
    dispatch(openSkillDetails(skill.code));
  };

  const onGoBack = () => {
    history.push(`/challenge/details/${skill.code}/${challenge.code}`);
  };

  const avatarMessage = isInViewMode
    ? `Voici quelques retours concernant ton défi pour continuer à t'améliorer 🚀`
    : `Félicitation ${learnerInformation?.firstName} 🎉 Voici quelques retours concernant ton défi pour continuer à t'améliorer 🚀`;

  return (
    <AppContainer hideNavBar>
      <div
        className={`challenge-results-container ${isInViewMode && "view-mode"}`}
      >
        <Render when={isInViewMode}>
          <BackButton onClick={onGoBack} />
        </Render>
        <Avatar message={avatarMessage} />
        <motion.div variants={topToBottom} className="title">
          Retours sur ton défi
        </motion.div>
        <motion.div variants={leftToRight} className="goal">
          <div className="icon">
            <FiAward />
          </div>
          <div className="text">
            <div className="label">Objectif :</div>
            <MarkdownReader markdown={challenge.goal} />
          </div>
        </motion.div>
        <div className="results">
          <motion.div variants={leftToRight} className="result">
            <div className="label">Bilan général</div>
            <div className="feedback">
              {getGeneralFeedback(
                challenge.feedbackQuestions,
                learnerChallenge.feedback.userFeedbacks
              )}
            </div>
          </motion.div>
          <motion.div variants={leftToRight} className="result">
            <div className="label">Durée</div>
            <div className="diff">
              <div className="reference">
                Estimation : {challenge.duration} min(s)
              </div>
              <div
                className={`value ${
                  isTimeSpentAcceptable(
                    challenge.duration,
                    learnerChallenge.feedback.timeSpent
                  ) && "ok"
                }`}
              >
                Ta réponse : {learnerChallenge.feedback.timeSpent} min(s)
              </div>
            </div>
            <div className="feedback">
              {generateTimeFeedback(
                challenge.duration,
                learnerChallenge.feedback.timeSpent
              )}
            </div>
          </motion.div>
          <motion.div variants={leftToRight} className="result">
            <div className="label">Difficulté</div>
            <div className="diff">
              <div className="reference">
                Estimation : {levelToText(challenge.level)}
              </div>
              <div
                className={`value ${
                  levelToNumber(learnerChallenge.feedback.difficulty) <=
                    levelToNumber(challenge.level) && "ok"
                }`}
              >
                Ta réponse : {levelToText(learnerChallenge.feedback.difficulty)}
              </div>
            </div>
            <div className="feedback">
              {generateDifficultyFeedback(
                challenge.level,
                learnerChallenge.feedback.difficulty
              )}
            </div>
          </motion.div>
        </div>
        <Render when={!isInViewMode}>
          <div className="button">
            <Button text="Continuer" onClick={goToSkill} />
          </div>
        </Render>
      </div>
    </AppContainer>
  );
};

export default ChallengeResults;
