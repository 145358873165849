import { PRIVACY_POLICY } from "assets/text/privacy-policy";
import MarkdownReader from "modules/shared/components/MarkdownReader/MarkdownReader";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import Render from "modules/shared/components/Render/Render";
import { motion } from "framer-motion";
import { bottomToTop } from "modules/shared/animations/animations.config";
import Button from "modules/shared/components/Button/Button";
import { getAppContext } from "utils/app-context.util";

interface Params {
  canGoBack?: string;
}

const PrivacyPolicy: React.FC = () => {
  const { canGoBack } = useParams<Params>();
  const showButton = canGoBack === "true";

  const history = useHistory();

  const { topInset, bottomInset } = getAppContext();

  return (
    <div
      className="privacy-policy-container"
      style={{ marginTop: topInset, marginBottom: bottomInset }}
    >
      <div className="card">
        <MarkdownReader markdown={PRIVACY_POLICY} />
        <Render when={showButton}>
          <motion.div
            variants={bottomToTop}
            className="go-back"
            style={{ bottom: bottomInset }}
          >
            <Button
              className="cta"
              text="Retour"
              onClick={() => history.push("/profile")}
            />
          </motion.div>
        </Render>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
