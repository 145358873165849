import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LearnerChallenge } from "models/learner-challenge.model";

interface ChallengeState {
  learnerChallenges: LearnerChallenge[];
}

const initialState: ChallengeState = {
  learnerChallenges: [],
};

const ChallengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    setLearnerChallenges: (
      state,
      action: PayloadAction<LearnerChallenge[]>
    ) => {
      state.learnerChallenges = action.payload;
    },
  },
});

export const { setLearnerChallenges } = ChallengeSlice.actions;

export default ChallengeSlice.reducer;
