import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "./styles.scss";

const MarkdownReader: React.FC<{ markdown: string }> = ({ markdown }) => {
  const buildStyleObject = (color: string) => {
    if (color && color.length > 0) {
      const colorCode = color.match(/#\w*/g);

      return colorCode && colorCode[0] ? { color: colorCode[0] } : undefined;
    }

    return undefined;
  };

  return (
    <ReactMarkdown
      className="markdown-reader"
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[gfm]}
      children={markdown}
      components={{
        span: ({ node, children }) => {
          const styleProps = (node.properties as any)?.style;
          const style = buildStyleObject(styleProps);
          return <span style={style}>{children}</span>;
        },
      }}
    />
  );
};

export default MarkdownReader;
