import Lottie from "react-lottie";
import brainLoaderAnimationData from "assets/lottie/brain-loader.json";
import brickLoaderAnimationData from "assets/lottie/brick-loader.json";
import checkAnimationData from "assets/lottie/check.json";
import "./styles.scss";
import Render from "../Render/Render";

const BrainLoaderOptions = {
  loop: true,
  autoplay: true,

  animationData: brainLoaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const BrainLoader: React.FC = () => {
  return (
    <div className="brain-loader-component">
      <div className="lottie">
        <Lottie options={BrainLoaderOptions} />
      </div>
    </div>
  );
};

const BrickLoaderOptions = {
  loop: true,
  autoplay: true,

  animationData: brickLoaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const SpinnerLoader: React.FC = () => {
  return (
    <div className="spinner-loader-component">
      <div className="lottie">
        <Lottie options={BrickLoaderOptions} />
      </div>
    </div>
  );
};

const CheckOptions = {
  loop: true,
  autoplay: true,

  animationData: checkAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const SendingLoader: React.FC<{
  isSending: boolean;
  sendingText: string;
  showConfirmation: boolean;
  confirmationText: string;
}> = ({ isSending, showConfirmation, sendingText, confirmationText }) => {
  return (
    <div className="sending-loader-component">
      <Render when={isSending}>
        <div className="text">{sendingText}</div>
        <SpinnerLoader />
      </Render>
      <Render when={showConfirmation}>
        <div className="text">{confirmationText}</div>
        <div className="lottie">
          <Lottie options={CheckOptions} />
        </div>
      </Render>
    </div>
  );
};
