import { createAsyncThunk } from "@reduxjs/toolkit";
import { history } from "../services/routing/history";

export const openSkillDetails = createAsyncThunk<void, string>(
  "navigation/skill-details",
  async (skillCode) => {
    history.push(`/skill/details/${skillCode}`);
  }
);

export const openChallengeDetails = createAsyncThunk<
  void,
  { challengeCode: string; skillCode: string }
>("navigation/challenge-details", async ({ challengeCode, skillCode }) => {
  history.push(`/challenge/details/${skillCode}/${challengeCode}`);
});

export const openSkillEvaluationResults = createAsyncThunk<void, string>(
  "navigation/skill-evaluation-results",
  async (skillCode) => {
    history.push(`/skill/results/${skillCode}`);
  }
);
