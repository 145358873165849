import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import profileDataAnimation from "assets/lottie/profile.json";
import "./styles.scss";
import Lottie from "react-lottie";
import { useAppDispatch, useAppSelector } from "modules/store";
import { motion } from "framer-motion";
import {
  bottomToTop,
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import { FiChevronRight, FiFileText } from "react-icons/fi";
import Button from "modules/shared/components/Button/Button";
import { logOut } from "modules/user-session-initializer.action";
import { useHistory } from "react-router-dom";

const ProfileOptions = {
  loop: true,
  autoplay: true,

  animationData: profileDataAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Profile: React.FC = () => {
  const history = useHistory();

  const { data: learnerInformation } = useAppSelector(
    (state) => state.learnerInformation
  );

  const dispatch = useAppDispatch();

  const signOut = () => {
    dispatch(logOut());
  };

  return (
    <AppContainer>
      <div className="profile-container">
        <div className="animation">
          <div className="lottie">
            <Lottie options={ProfileOptions} />
          </div>
        </div>
        <motion.div variants={topToBottom} className="user-infos">
          <div className="name">
            {learnerInformation?.firstName} {learnerInformation?.lastName}
          </div>
          <div className="unit">{learnerInformation?.unit}</div>
          <div className="organization">
            {learnerInformation?.organizationName}
          </div>
        </motion.div>
        <motion.div
          variants={leftToRight}
          className="privacy-policy"
          onClick={() => history.push("/privacy-policy/true")}
        >
          <div className="left">
            <div className="icon">
              <FiFileText />
            </div>
            <div className="text">Politique de confidentialité</div>
          </div>
          <div className="icon">
            <FiChevronRight />
          </div>
        </motion.div>
        <motion.div variants={bottomToTop}>
          <div className="logout">
            <Button className="cta" text="Déconnexion" onClick={signOut} />
          </div>
        </motion.div>
      </div>
    </AppContainer>
  );
};

export default Profile;
