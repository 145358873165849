import { Route, Switch, useRouteMatch } from "react-router-dom";
import SkillDetails from "./screen/SkillDetails/SkillDetails";
import SkillEvaluation from "./screen/SkillEvaluation/SkillEvaluation";
import EvaluationResults from "./screen/EvaluationResults/EvaluationResults";

const SkillRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:skillCode`}>
        <SkillDetails />
      </Route>
      <Route path={`${path}/evaluation/:skillCode/:isFinal?`}>
        <SkillEvaluation />
      </Route>
      <Route path={`${path}/results/:skillCode`}>
        <EvaluationResults />
      </Route>
    </Switch>
  );
};

export default SkillRoutes;
