import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Progress from "./screens/Progress/Progress";

const ProgressRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <Progress />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default ProgressRoutes;
