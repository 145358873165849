import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";

const MiscRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:canGoBack?`}>
        <PrivacyPolicy />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default MiscRoutes;
