import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { getLearnerProgram } from "modules/shared/services/learner/learner.service";
import { setContentReferential } from "modules/shared/stores/content-referential/content-referential.reducer";
import { ThunkApi } from "modules/store";

export const loadContentReferential = createAsyncThunk<void, void, ThunkApi>(
  "content-referential",
  async (_, { dispatch }) => {
    const { status, data } = await getLearnerProgram();

    if (status === ApiOperationStatus.Success && data) {
      dispatch(setContentReferential(data));
    }
  }
);
