import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./auth/store/session.reducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import learnerInformationReducer from "./shared/stores/learner/learner-information.reducer";
import contentReferentialReducer from "./shared/stores/content-referential/content-referential.reducer";
import learnerProgressReducer from "./shared/stores/learner/learner-progress.reducer";
import challengeReducer from "./challenge/store/challenge.reducer";

const store = configureStore({
  reducer: {
    session: sessionReducer,
    learnerInformation: learnerInformationReducer,
    contentReferential: contentReferentialReducer,
    learnerProgress: learnerProgressReducer,
    challenge: challengeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface ThunkApi {
  dispatch: AppDispatch;
  state: RootState;
}

export default store;
