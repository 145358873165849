import config from "config";
import { ApiOperationResult } from "models/api-operation-result.model";
import { EvaluationResponse } from "models/learner-progress.model";
import { getCurrentUserToken } from "modules/shared/services/authentication/authentication.service";
import { POST } from "modules/shared/services/http-operations/http-operations";

const LEARNER_EVALUATION_ROUTE = `${config.apiUrl}/evaluation`;

export const setInitialEvaluation = async (
  skillCode: string,
  evaluationResponses: EvaluationResponse[]
): Promise<ApiOperationResult<unknown>> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_EVALUATION_ROUTE}/initialEvaluation`;
  const result = await POST<{}, {}>(url, token, {
    skillCode,
    evaluationResponses,
  });
  return result;
};

export const setFinalEvaluation = async (
  skillCode: string,
  evaluationResponses: EvaluationResponse[]
): Promise<ApiOperationResult<unknown>> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_EVALUATION_ROUTE}/finalEvaluation`;
  const result = await POST<{}, {}>(url, token, {
    skillCode,
    evaluationResponses,
  });
  return result;
};
