import config from "config";
import {
  ApiOperationResult,
  ApiOperationStatus,
} from "models/api-operation-result.model";
import { LoginResponse } from "models/authentication.model";
import {
  getCurrentUserAuthData,
  setAuthData,
} from "modules/shared/services/authentication/authentication.service";
import { POST } from "modules/shared/services/http-operations/http-operations";

const AUTH_ROUTE = `${config.apiUrl}/auth`;

export const signIn = async (
  login: string,
  password: string
): Promise<ApiOperationResult<LoginResponse>> => {
  const url = `${AUTH_ROUTE}/login`;
  const result = await POST<{}, LoginResponse>(url, undefined, {
    username: login,
    password,
  });
  return result;
};

export const storeAuthData = (data: LoginResponse) => {
  setAuthData(data.accessToken, data.username, data.identityProviderId);
};

export const authUser = async (): Promise<
  ApiOperationResult<LoginResponse>
> => {
  const { username, identityProviderId, token } = getCurrentUserAuthData();

  if (username && identityProviderId) {
    const url = `${AUTH_ROUTE}/login/identityProviderId`;
    const result = await POST<{}, LoginResponse>(url, undefined, {
      username,
      identityProviderId,
      token,
    });
    return result;
  }

  return {
    status: ApiOperationStatus.Error,
  };
};
