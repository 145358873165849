import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "modules/store";
import { openChallengeDetails } from "modules/shared/actions/navigation.action";
import Button, { BackButton } from "modules/shared/components/Button/Button";
import { motion } from "framer-motion";
import {
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import Avatar from "modules/shared/components/Avatar/Avatar";
import {
  ChallengeLevel,
  FeedbackQuestion as FeedbackQuestionModel,
} from "models/content-referential.model";
import { FeedbackQuestionType } from "models/content-referential.model";
import Render from "modules/shared/components/Render/Render";
import { useEffect, useState } from "react";
import { UserFeedback } from "models/learner-challenge.model";
import TextInput from "modules/shared/components/TextInput/TextInput";
import FloatingButtonContainer from "modules/shared/components/FloatingButtonContainer/FloatingButtonContainer";
import { completeChallenge } from "modules/challenge/actions/learner-challenge.action";
import { SendingLoader } from "modules/shared/components/Loader/Loader";

interface Params {
  learnerChallengeId: string;
}

const ChallengeFeedback: React.FC = () => {
  const history = useHistory();

  const { learnerChallengeId } = useParams<Params>();

  const { data: learnerInformation } = useAppSelector(
    (state) => state.learnerInformation
  );

  const { learnerChallenges } = useAppSelector((state) => state.challenge);
  const learnerChallenge = learnerChallenges.find(
    (lc) => lc.id === +learnerChallengeId
  );

  const skillCode = learnerChallenge?.skillCode;
  const challengeCode = learnerChallenge?.challengeCode;

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);
  const challenge = skill?.challenges?.find((c) => c.code === challengeCode);

  const dispatch = useAppDispatch();

  const [userFeedbacks, setUserFeebacks] = useState<UserFeedback[]>([]);
  const [timeSpentFeedback, setTimeSpentFeedback] = useState<number>(5);
  const [difficultyFeedback, setDifficultyFeedback] =
    useState<ChallengeLevel>();
  const [isChallengeSubmitting, setIsChallengeSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleUserFeebacks = (feedback: UserFeedback) => {
    const alreadyExists =
      userFeedbacks.findIndex((f) => f.questionCode === feedback.questionCode) >
      -1;

    if (alreadyExists) {
      setUserFeebacks([
        ...userFeedbacks.filter(
          (f) => f.questionCode !== feedback.questionCode
        ),
        feedback,
      ]);
    } else {
      setUserFeebacks([...userFeedbacks, feedback]);
    }
  };

  useEffect(() => {
    if (showConfirmation) {
      const timeout = setTimeout(() => {
        history.push(`/challenge/results/${learnerChallengeId}`);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showConfirmation, history, learnerChallengeId]);

  if (!skill || !challenge || !learnerChallenge) return null;

  const onGoBack = () => {
    dispatch(
      openChallengeDetails({
        challengeCode: challenge.code,
        skillCode: skill.code,
      })
    );
  };

  const canValidateUserFeedbacks = () => {
    if (userFeedbacks.length === challenge.feedbackQuestions.length) {
      const hasEmptyCode =
        userFeedbacks.findIndex(
          (f) => !f.questionCode || f.questionCode.length < 10
        ) > -1;
      const hasEmptyResponse =
        userFeedbacks.findIndex((f) => !f.response || f.response.length < 10) >
        -1;

      if (!hasEmptyCode && !hasEmptyResponse) return true;
    }

    return false;
  };

  const submit = async () => {
    if (
      timeSpentFeedback > 0 &&
      difficultyFeedback &&
      canValidateUserFeedbacks()
    ) {
      setIsChallengeSubmitting(true);

      await dispatch(
        completeChallenge({
          learnerChallengeId: +learnerChallengeId,
          userFeedbacks,
          timeSpent: timeSpentFeedback,
          difficulty: difficultyFeedback,
        })
      );

      setIsChallengeSubmitting(false);
      setShowConfirmation(true);
    }
  };

  return (
    <AppContainer hideNavBar>
      <div
        className={`challenge-feedback-container ${
          (isChallengeSubmitting || showConfirmation) && "full-height"
        }`}
      >
        <Render when={isChallengeSubmitting || showConfirmation}>
          <SendingLoader
            isSending={isChallengeSubmitting}
            showConfirmation={showConfirmation}
            sendingText="Validation du défi..."
            confirmationText="Défi validé !"
          />
        </Render>
        <Render when={!isChallengeSubmitting && !showConfirmation}>
          <BackButton onClick={onGoBack} />
          <Avatar
            message={`Bravo ${learnerInformation?.firstName} 💪 Pour finir, prends du recul et fais le bilan de ton activité en répondant à ces questions 👇`}
          />
          <motion.div variants={topToBottom} className="note">
            <span>Nota Bene :</span> Ne t'en fais pas, il n'y a pas de bonne ou
            de mauvaise réponse <span>🙂</span> Sois juste sincère{" "}
            <span>😉</span>
          </motion.div>
          <div className="questions">
            {challenge.feedbackQuestions.map((feedbackQuestion) => (
              <FeedbackQuestion
                key={feedbackQuestion.code}
                {...feedbackQuestion}
                setFeedback={handleUserFeebacks}
              />
            ))}
            <TimeSpentInput setTimeSpentFeedback={setTimeSpentFeedback} />
            <DifficultyInput setDifficultyFeedback={setDifficultyFeedback} />
          </div>
          <FloatingButtonContainer>
            <Button
              text="J'enregistre mes réponses"
              disabled={
                !canValidateUserFeedbacks() ||
                timeSpentFeedback < 1 ||
                !difficultyFeedback
              }
              onClick={submit}
            />
          </FloatingButtonContainer>
        </Render>
      </div>
    </AppContainer>
  );
};

export default ChallengeFeedback;

interface FeedbackQuestionProps extends FeedbackQuestionModel {
  setFeedback: (feedback: UserFeedback) => void;
}

const FeedbackQuestion: React.FC<FeedbackQuestionProps> = ({
  code,
  question,
  type,
  choices,
  setFeedback,
}) => {
  const [textResponse, setTextResponse] = useState<string>("");
  const [choiceResponse, setChoiceResponse] = useState<string>("");

  const handleTextResponse = (e: any) => {
    const text = e.target.textContent;
    setTextResponse(text);
    setFeedback({ questionCode: code, response: text });
  };

  const handleChoiceResponse = (choiceCode: string) => {
    setChoiceResponse(choiceCode);
    setFeedback({ questionCode: code, response: choiceCode });
  };

  return (
    <motion.div variants={leftToRight} className="feedback-question">
      <div className="question">{question}</div>
      <div className="response">
        <Render when={type === FeedbackQuestionType.text}>
          <div
            className={`input-label ${textResponse.length > 9 && "checked"}`}
          >
            10 caractères minimun
          </div>
          <span
            className={`input ${textResponse.length > 9 && "checked"}`}
            role="textbox"
            contentEditable
            onInput={handleTextResponse}
          />
        </Render>
        <Render when={type === FeedbackQuestionType.choice}>
          <div className="choices">
            {choices.map((choice) => (
              <div
                key={choice.code}
                className={`choice ${
                  choiceResponse === choice.code && "selected"
                }`}
                onClick={() => handleChoiceResponse(choice.code)}
              >
                {choice.text}
              </div>
            ))}
          </div>
        </Render>
      </div>
    </motion.div>
  );
};

const TimeSpentInput: React.FC<{
  setTimeSpentFeedback: (timeSpent: number) => void;
}> = ({ setTimeSpentFeedback }) => {
  const [timeSpent, setTimeSpent] = useState(5);

  const handleTimeSpent = (time: number) => {
    setTimeSpent(time);
    setTimeSpentFeedback(time);
  };

  return (
    <motion.div variants={leftToRight} className="feedback-question">
      <div className="question">Combien de temps as-tu passé sur ce défi ?</div>
      <div className="response">
        <TextInput
          className="time-spent-input"
          type="number"
          text={`${timeSpent}`}
          onTextChange={(text) => handleTimeSpent(+text)}
        />
        <div className="time-spent-input-label">minute(s)</div>
      </div>
    </motion.div>
  );
};

const DIFFICULTY_OPTIONS = [
  { code: ChallengeLevel.EASY, text: "Facile" },
  { code: ChallengeLevel.MEDIUM, text: "Moyen" },
  { code: ChallengeLevel.HARD, text: "Difficile" },
];

const DifficultyInput: React.FC<{
  setDifficultyFeedback: (difficulty: ChallengeLevel) => void;
}> = ({ setDifficultyFeedback }) => {
  const [difficulty, setDifficulty] = useState<ChallengeLevel>();

  const handleDifficulty = (value: ChallengeLevel) => {
    setDifficulty(value);
    setDifficultyFeedback(value);
  };

  return (
    <motion.div variants={leftToRight} className="feedback-question">
      <div className="question">
        À quel point as-tu trouvé ce défi difficile ?
      </div>
      <div className="response">
        <div className="choices">
          {DIFFICULTY_OPTIONS.map((option) => (
            <div
              key={option.code}
              className={`choice ${difficulty === option.code && "selected"}`}
              onClick={() => handleDifficulty(option.code)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};
