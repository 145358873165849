import { Challenge } from "models/content-referential.model";
import "./styles.scss";
import { motion } from "framer-motion";
import { leftToRight } from "modules/shared/animations/animations.config";
import { FiCheckCircle, FiClock, FiLock } from "react-icons/fi";
import { useAppDispatch } from "modules/store";
import { openChallengeDetails } from "modules/shared/actions/navigation.action";
import Render from "../Render/Render";

interface ChallengeCardProps {
  challenge: Challenge;
  skillCode: string;
  isLocked: boolean;
  isInProgress: boolean;
  isCompleted: boolean;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  skillCode,
  isLocked,
  isInProgress,
  isCompleted,
}) => {
  const dispatch = useAppDispatch();

  const openChallenge = () => {
    if (!isLocked) {
      dispatch(
        openChallengeDetails({
          challengeCode: challenge.code,
          skillCode: skillCode,
        })
      );
    }
  };

  return (
    <motion.div
      variants={leftToRight}
      className={`challenge-card ${isInProgress && "in-progress-status"} ${
        isCompleted && "completed-status"
      }`}
      onClick={openChallenge}
    >
      <Render when={isLocked}>
        <div className="lock">
          <div className="icon">
            <FiLock />
          </div>
        </div>
      </Render>
      <div className="challenge-card-order">{challenge.order}</div>
      <div className="challenge-card-image">
        <img src={challenge.imageUrl} alt="challenge" />
      </div>
      <div className={`challenge-card-details`}>
        <div className="title">{challenge.title}</div>
        <div className="duration">
          <div className="icon">
            <FiClock />
          </div>
          <div className="minutes">{challenge.duration} mins</div>
        </div>
        <Render when={isInProgress}>
          <div className="in-progress">En cours...</div>
        </Render>
        <Render when={isCompleted}>
          <div className="completed">
            <div className="text">Terminé</div>
            <div className="icon">
              <FiCheckCircle />
            </div>
          </div>
        </Render>
      </div>
    </motion.div>
  );
};

export default ChallengeCard;
