import { motion } from "framer-motion";
import "./styles.scss";
import {
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import Lottie from "react-lottie";
import avatarAnimationData from "assets/lottie/avatar.json";

const AvatarOptions = {
  loop: true,
  autoplay: true,

  animationData: avatarAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface AvatarProps {
  message: string;
}

const Avatar: React.FC<AvatarProps> = ({ message }) => {
  return (
    <div className="avatar-component">
      <motion.div variants={topToBottom} className="avatar">
        <div className="lottie">
          <Lottie options={AvatarOptions} />
        </div>
      </motion.div>
      <motion.div variants={leftToRight} className="message">
        <div className="text">{message}</div>
      </motion.div>
    </div>
  );
};

export default Avatar;
