import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Profile from "./screens/Profile/Profile";

const ProfileRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <Profile />
      </Route>
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default ProfileRoutes;
