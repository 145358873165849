import { motion } from "framer-motion";
import "./styles.scss";
import { bottomToTop } from "modules/shared/animations/animations.config";
import { getAppContext } from "utils/app-context.util";

interface FloatingButtonContainerProps {
  children: React.ReactNode;
}

const FloatingButtonContainer: React.FC<FloatingButtonContainerProps> = ({
  children,
}) => {
  const { bottomInset } = getAppContext();

  return (
    <motion.div
      variants={bottomToTop}
      className="floating-button-container"
      style={{ bottom: bottomInset }}
    >
      {children}
    </motion.div>
  );
};

export default FloatingButtonContainer;
