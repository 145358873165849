import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LearnerProgress } from "models/learner-progress.model";

interface LearnerProgressState {
  data?: LearnerProgress;
}

const initialState: LearnerProgressState = {
  data: undefined,
};

const LearnerProgressSlice = createSlice({
  name: "learner-progress",
  initialState,
  reducers: {
    setLearnerProgress: (state, action: PayloadAction<LearnerProgress>) => {
      state.data = action.payload;
    },
    clearLearnerProgress: (state) => {
      state.data = undefined;
    },
  },
});

export const { setLearnerProgress, clearLearnerProgress } =
  LearnerProgressSlice.actions;

export default LearnerProgressSlice.reducer;
