import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Home from "./screens/Home/Home";

const HomeRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path}>
        <Home />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default HomeRoutes;
