export const PRIVACY_POLICY = `
## Politique de confidentialité

<br>

Nous prenons très au sérieux la confidentialité de vos informations personnelles et nous nous engageons à protéger la confidentialité de vos informations. Cette politique de confidentialité décrit les types d'informations que nous collectons auprès de vous, comment nous les utilisons et les protégeons, et les choix que vous avez concernant l'utilisation de vos informations.

<br>

**Types d'informations collectées**

<br>

Nous collectons des informations personnelles, telles que votre nom et votre unité (ou classe), lorsque votre établissement (ou votre entreprise) nous les fournit volontairement. Nous pouvons également collecter des informations démographiques telles que votre âge, votre sexe et votre lieu de résidence.

<br>

**Comment nous utilisons vos informations**

<br>

Nous utilisons ces informations pour vous inscrire à notre programme de développement et pour personnaliser votre expérience sur notre plateforme. Nous pouvons également utiliser vos informations pour améliorer notre plateforme, pour réaliser des analyses statistiques et pour nous conformer aux lois et réglementations applicables.

<br>

**Protection de vos informations**

<br>

Nous prenons des mesures de sécurité raisonnables pour protéger vos informations personnelles contre l'accès non autorisé, la divulgation ou la modification.

<br>

**Partage de vos informations**

<br>

Nous ne vendons pas et ne louons pas vos informations personnelles à des tiers.

<br>

**Vos choix**

<br>

Vous pouvez à tout moment demander la suppression de vos données.

<br>

**Modifications de notre politique de confidentialité**

<br>

Nous pouvons modifier cette politique de confidentialité de temps à autre. Toute modification sera publiée sur notre plateforme et entrera en vigueur dès sa publication.

<br>

**Nous contacter**

<br>

Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, n'hésitez pas à nous contacter.

<br>

contact@akomadagascar.mg
`;
