import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Program } from "models/content-referential.model";

interface ContentReferentialState {
  program?: Program;
}

const initialState: ContentReferentialState = {
  program: undefined,
};

const ContentReferentialSlice = createSlice({
  name: "content-referential",
  initialState,
  reducers: {
    setContentReferential: (state, action: PayloadAction<Program>) => {
      state.program = action.payload;
    },
    clearContentReferential: (state) => {
      state.program = undefined;
    },
  },
});

export const { setContentReferential, clearContentReferential } =
  ContentReferentialSlice.actions;

export default ContentReferentialSlice.reducer;
