interface RenderProps {
  when: boolean;
  children: React.ReactNode;
}

const Render: React.FC<RenderProps> = ({ when, children }) => {
  if (!when) return null;

  return <>{children}</>;
};

export default Render;
