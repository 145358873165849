import React from "react";
import { motion } from "framer-motion";
import { container } from "modules/shared/animations/animations.config";
import logo from "assets/images/logo.png";
import "./styles.scss";
import {
  CircleDecoration,
  SquareDecoration,
  Wave,
} from "modules/shared/components/Decoration/Decoration";
import { getAppContext } from "utils/app-context.util";

interface AuthContainerProps {
  children: React.ReactNode;
}

const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  const { topInset } = getAppContext();

  const height = topInset ? `calc(100vh - ${topInset})` : undefined;

  return (
    <motion.div
      variants={container}
      initial="initial"
      animate="enter"
      exit="exit"
      className="auth-container"
      style={{ paddingTop: topInset, height: height }}
    >
      <div className="auth-container-card">
        {children}
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </div>

      <CircleDecoration className="deco-top-left" />
      <SquareDecoration className="deco-bottom-right" />
      <Wave />
    </motion.div>
  );
};

export default AuthContainer;
