import AuthContainer from "modules/auth/components/AuthContainer/AuthContainer";
import "./styles.scss";
import { motion } from "framer-motion";
import { leftToRight } from "modules/shared/animations/animations.config";
import Button from "modules/shared/components/Button/Button";
import { useHistory } from "react-router-dom";

const ValidityError: React.FC = () => {
  const history = useHistory();

  return (
    <AuthContainer>
      <div className="validity-header"></div>
      <div className="validity-error">
        <motion.div variants={leftToRight} className="text">
          Malheureusement, ton compte a expiré 😔
        </motion.div>
        <div className="cta">
          <Button text="Retour" onClick={() => history.push("/auth")} />
        </div>
      </div>
    </AuthContainer>
  );
};

export default ValidityError;
