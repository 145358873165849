import {
  ChallengeLevel,
  FeedbackQuestion,
  FeedbackQuestionType,
} from "models/content-referential.model";
import "./styles.scss";
import { UserFeedback } from "models/learner-challenge.model";
import { motion } from "framer-motion";
import { leftToRight } from "modules/shared/animations/animations.config";
import { levelToText } from "modules/challenge/services/result-handler.service";
import { formatDate } from "utils/date.util";

interface FeedbackViewerProps {
  feedbackQuestions: FeedbackQuestion[];
  userFeedbacks: UserFeedback[];
  timeSpent: number;
  difficulty: ChallengeLevel;
  completionDate: Date;
}

const FeedbackViewer: React.FC<FeedbackViewerProps> = ({
  feedbackQuestions,
  userFeedbacks,
  timeSpent,
  difficulty,
  completionDate,
}) => {
  return (
    <div className="feedback-viewer">
      <motion.div variants={leftToRight} className="title">
        Tes réponses
      </motion.div>
      <div className="feedbacks">
        {feedbackQuestions.map((feedbackQuestion) => (
          <Response
            key={feedbackQuestion.code}
            feedbackQuestion={feedbackQuestion}
            userResponse={
              userFeedbacks.find(
                (r) => r.questionCode === feedbackQuestion.code
              )?.response ?? ""
            }
          />
        ))}
        <motion.div variants={leftToRight} className="feedback">
          <div className="question">
            Combien de temps as-tu passé sur ce défi ?
          </div>
          <div className="response">{timeSpent} min(s)</div>
        </motion.div>
        <motion.div variants={leftToRight} className="feedback">
          <div className="question">
            À quel point as-tu trouvé ce défi difficile ?
          </div>
          <div className="response">{levelToText(difficulty)}</div>
        </motion.div>
      </div>
      <div className="completion-date">
        Terminé le {formatDate(completionDate, "/")}
      </div>
    </div>
  );
};

export default FeedbackViewer;

interface ResponseProps {
  feedbackQuestion: FeedbackQuestion;
  userResponse: string;
}

const Response: React.FC<ResponseProps> = ({
  feedbackQuestion,
  userResponse,
}) => {
  const renderResponse = () => {
    if (feedbackQuestion.type === FeedbackQuestionType.choice) {
      return feedbackQuestion.choices.find((c) => c.code === userResponse)
        ?.text;
    }

    return userResponse;
  };

  return (
    <motion.div variants={leftToRight} className="feedback">
      <div className="question">{feedbackQuestion.question}</div>
      <div className="response">{renderResponse()}</div>
    </motion.div>
  );
};
