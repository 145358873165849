import { Route, Switch, useRouteMatch } from "react-router-dom";
import ChallengeDetails from "./screens/ChallengeDetails/ChallengeDetails";
import ChallengeFeedback from "./screens/ChallengeFeedback/ChallengeFeedback";
import ChallengeResults from "./screens/ChallengeResults/ChallengeResults";

const ChallengeRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:skillCode/:challengeCode`}>
        <ChallengeDetails />
      </Route>
      <Route path={`${path}/feedback/:learnerChallengeId`}>
        <ChallengeFeedback />
      </Route>
      <Route path={`${path}/results/:learnerChallengeId/:viewMode?`}>
        <ChallengeResults />
      </Route>
    </Switch>
  );
};

export default ChallengeRoutes;
