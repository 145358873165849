export interface AuthenticationData {
  token?: string;
}

export enum LoginStatus {
  succeed,
  failed,
}

export interface LoginResponse {
  accessToken: string;
  username: string;
  identityProviderId: string;
}

export enum LocalStorageKey {
  TOKEN = "AKO_API_TOKEN",
  USERNAME = "AKO_API_USERNAME",
  IDENTITY_PROVIDER_ID = "AKO_API_IDENTITY_PROVIDER_ID",
}
