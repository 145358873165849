import { LocalStorageKey } from "models/authentication.model";

export const setAuthData = (
  token: string,
  username: string,
  identityProviderId: string
) => {
  localStorage.setItem(LocalStorageKey.TOKEN, token);
  localStorage.setItem(LocalStorageKey.USERNAME, username);
  localStorage.setItem(
    LocalStorageKey.IDENTITY_PROVIDER_ID,
    identityProviderId
  );
};

export const getCurrentUserToken = () => {
  return localStorage.getItem(LocalStorageKey.TOKEN) || "";
};

export const clearAuthData = () => {
  localStorage.clear();
};

export const getCurrentUserAuthData = () => {
  const username = localStorage.getItem(LocalStorageKey.USERNAME);
  const identityProviderId = localStorage.getItem(
    LocalStorageKey.IDENTITY_PROVIDER_ID
  );
  const token = localStorage.getItem(LocalStorageKey.TOKEN);
  return { username, identityProviderId, token };
};
