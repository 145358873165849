export const formatDate = (date: Date, separator: string = "-") => {
  const convertedDate = new Date(date);
  const day = convertedDate.getUTCDate();
  const formatedDay = day.toString().length < 2 ? `0${day}` : day;
  const month = Number(convertedDate.getUTCMonth()) + 1;
  const formatedMonth = month.toString().length < 2 ? `0${month}` : month;
  const year = convertedDate.getUTCFullYear();
  return `${formatedDay}${separator}${formatedMonth}${separator}${year}`;
};

export const formatMinute = (minutes: number) => {
  if (minutes === 60) {
    return "1h";
  } else if (minutes > 60) {
    const hour = Math.floor(minutes / 60);
    const remainingMinute = minutes % 60;

    const hourString = hour < 9 ? `0${hour}` : `${hour}`;
    const minutesString =
      remainingMinute < 9 ? `0${remainingMinute}` : `${remainingMinute}`;

    return remainingMinute !== 0
      ? `${hourString}h${minutesString}min(s)`
      : `${hourString}h`;
  } else {
    return `${minutes} min(s)`;
  }
};
