import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import "./styles.scss";
import { BackButton } from "modules/shared/components/Button/Button";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  bottomToTop,
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import { EvaluationQuestion } from "models/content-referential.model";
import { EvaluationResponse } from "models/learner-progress.model";
import { useAppDispatch, useAppSelector } from "modules/store";
import { openSkillDetails } from "modules/shared/actions/navigation.action";

interface Params {
  skillCode: string;
}

const EvaluationResults: React.FC = () => {
  const { skillCode } = useParams<Params>();

  const dispatch = useAppDispatch();

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);

  const { data: learnerProgress } = useAppSelector(
    (state) => state.learnerProgress
  );
  const skillProgress = learnerProgress?.skillsProgress?.find(
    (sp) => sp.skillCode === skillCode
  );

  const goBack = () => {
    dispatch(openSkillDetails(skillCode));
  };

  if (
    !skill ||
    !skillProgress ||
    !skillProgress.initialEvaluation ||
    !skillProgress.finalEvaluation
  )
    return null;

  const evaluationQuestions = [...skill.evaluationQuestions];
  evaluationQuestions.sort((a, b) => a.order - b.order);

  return (
    <AppContainer>
      <div className="evaluation-results-container">
        <BackButton onClick={goBack} />
        <motion.div variants={leftToRight} className="header">
          <div className="title">Tes résultats</div>
        </motion.div>
        <div className="body">
          <div className="skill-infos">
            <motion.div variants={topToBottom} className="title">
              {skill.title}
            </motion.div>
            <motion.div
              variants={topToBottom}
              className="theme"
              style={{ color: skill.skillTheme.color }}
            >
              {skill.skillTheme.title}
            </motion.div>
          </div>
          <div className="results">
            {evaluationQuestions.map((evaluationQuestion) => (
              <Result
                key={evaluationQuestion.code}
                evaluationQuestion={evaluationQuestion}
                initialResponse={
                  skillProgress.initialEvaluation!.responses.find(
                    (r) => r.questionCode === evaluationQuestion.code
                  )!
                }
                finalResponse={
                  skillProgress.finalEvaluation!.responses.find(
                    (r) => r.questionCode === evaluationQuestion.code
                  )!
                }
              />
            ))}
          </div>
          <motion.div variants={bottomToTop} className="legends">
            <div className="colors">
              <div className="evaluation initial">
                <div className="text">1er auto-évaluation</div>
              </div>
              <div className="evaluation final">
                <div className="text">2e auto-évaluation</div>
              </div>
            </div>
            <div className="indication">Point minimum : 1</div>
            <div className="indication">Point maximum : 10</div>
          </motion.div>
        </div>
      </div>
    </AppContainer>
  );
};

export default EvaluationResults;

interface ResultProps {
  evaluationQuestion: EvaluationQuestion;
  initialResponse: EvaluationResponse;
  finalResponse: EvaluationResponse;
}

const Result: React.FC<ResultProps> = ({
  evaluationQuestion,
  initialResponse,
  finalResponse,
}) => {
  return (
    <motion.div variants={leftToRight} className="result">
      <div className="question">{evaluationQuestion.label}</div>
      <div className="progress">
        <Rate rate={initialResponse.rate} />
        <Rate rate={finalResponse.rate} />
      </div>
    </motion.div>
  );
};

const Rate: React.FC<{ rate: number }> = ({ rate }) => {
  return (
    <div className="rate">
      <div className="value">{rate}</div>
      <div className="bar">
        <div style={{ width: `${rate * 10}%` }}></div>
      </div>
    </div>
  );
};
