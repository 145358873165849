export interface ApiOperationResult<T> {
  status: ApiOperationStatus;
  data?: T;
}

export enum ApiOperationStatus {
  Error = "ERROR",
  Success = "SUCCESS",
  Unauthorized = "UNAUTHORIZED",
}
