import { Skill } from "models/content-referential.model";
import "./styles.scss";
import { motion } from "framer-motion";
import { leftToRight } from "modules/shared/animations/animations.config";
import { FiClock, FiKey } from "react-icons/fi";
import { formatMinute } from "utils/date.util";
import MarkdownReader from "../MarkdownReader/MarkdownReader";
import { useAppDispatch } from "modules/store";
import { openSkillDetails } from "modules/shared/actions/navigation.action";
import { aggregateSkillDuration } from "modules/shared/services/content-referential/skill.service";

interface SkillCardProps {
  skill: Skill;
}

const SkillCard: React.FC<SkillCardProps> = ({ skill }) => {
  const dispatch = useAppDispatch();

  const skillDuration = aggregateSkillDuration(skill);

  const goToDetails = () => {
    dispatch(openSkillDetails(skill.code));
  };

  return (
    <motion.div
      variants={leftToRight}
      className="skill-card"
      onClick={goToDetails}
    >
      <div className="image">
        <img src={skill.imageUrl} alt="skill" />
      </div>
      <div className="details" style={{ borderColor: skill.skillTheme.color }}>
        <div className="skill-title">{skill.title}</div>
        <div className="skill-theme" style={{ color: skill.skillTheme.color }}>
          {skill.skillTheme.title}
        </div>
        <div className="skill-description">
          <MarkdownReader markdown={skill.shortDescription} />
        </div>
        <div
          className="skill-metrics"
          style={{ color: skill.skillTheme.color }}
        >
          <div className="metric">
            <div className="metric">
              <div className="icon">
                <FiKey />
              </div>
              <div className="text">{skill.challenges.length} défi(s)</div>
            </div>
            <div className="icon">
              <FiClock />
            </div>
            <div className="text">{formatMinute(skillDuration)}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SkillCard;
