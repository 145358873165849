import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LearnerInformation } from "models/learner-information.model";

interface LearnerInformationState {
  data?: LearnerInformation;
}

const initialState: LearnerInformationState = {
  data: undefined,
};

const LearnerInformationSlice = createSlice({
  name: "learner-information",
  initialState,
  reducers: {
    setLearnerInformation: (
      state,
      action: PayloadAction<LearnerInformation>
    ) => {
      state.data = action.payload;
    },
    clearLearnerInformation: (state) => {
      state.data = undefined;
    },
  },
});

export const { setLearnerInformation, clearLearnerInformation } =
  LearnerInformationSlice.actions;

export default LearnerInformationSlice.reducer;
