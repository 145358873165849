import config from "config";
import { ApiOperationResult } from "models/api-operation-result.model";
import { GET } from "../http-operations/http-operations";
import { getCurrentUserToken } from "../authentication/authentication.service";
import { LearnerInformation } from "models/learner-information.model";
import { Program } from "models/content-referential.model";
import { LearnerProgress } from "models/learner-progress.model";

const LEARNER_ROUTE = `${config.apiUrl}`;

export const checkValidity = async (): Promise<
  ApiOperationResult<{ validity: boolean }>
> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_ROUTE}/subscription`;
  const result = await GET<{ validity: boolean }>(url, token);
  return result;
};

export const getLearnerInformation = async (): Promise<
  ApiOperationResult<LearnerInformation>
> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_ROUTE}/information`;
  const result = await GET<LearnerInformation>(url, token);
  return result;
};

export const getLearnerProgram = async (): Promise<
  ApiOperationResult<Program>
> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_ROUTE}/program`;
  const result = await GET<Program>(url, token);
  return result;
};

export const getLearnerProgress = async (): Promise<
  ApiOperationResult<LearnerProgress>
> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_ROUTE}/progress`;
  const result = await GET<LearnerProgress>(url, token);
  return result;
};
