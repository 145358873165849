import { motion } from "framer-motion";
import "./styles.scss";
import { bottomToTop, zoom } from "modules/shared/animations/animations.config";
import { FiArrowLeft } from "react-icons/fi";
import { getAppContext } from "utils/app-context.util";
import { hapticsImpact } from "modules/shared/services/app-shell-communication/app-shell-command-publisher.service";

interface BaseProps {
  onClick: () => void;
}

interface ButtonProps extends BaseProps {
  text: string;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  className,
  disabled,
  onClick,
}) => {
  const handleClick = () => {
    hapticsImpact();
    onClick();
  };

  return (
    <motion.div
      variants={bottomToTop}
      className={`button-component ${className} ${disabled && "disabled"}`}
      onClick={handleClick}
    >
      <div className="text">{text}</div>
    </motion.div>
  );
};

export default Button;

export const BackButton: React.FC<BaseProps> = ({ onClick }) => {
  const { topInset } = getAppContext();

  const handleClick = () => {
    hapticsImpact();
    onClick();
  };

  return (
    <motion.div
      variants={zoom}
      className="button-component back"
      onClick={handleClick}
      style={{ marginTop: topInset }}
    >
      <div className="icon">
        <FiArrowLeft />
      </div>
      <div className="text">Retour</div>
    </motion.div>
  );
};
