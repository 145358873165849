import {
  ChallengeLevel,
  FeedbackQuestion,
  FeedbackQuestionType,
} from "models/content-referential.model";
import { UserFeedback } from "models/learner-challenge.model";

export const isTimeSpentAcceptable = (duration: number, timeSpent: number) => {
  if (duration - 5 <= timeSpent && timeSpent <= duration + 5) {
    return true;
  }

  return false;
};

export const generateTimeFeedback = (duration: number, timeSpent: number) => {
  const limitMin = duration - 5;
  const limitMax = duration + 5;

  if (timeSpent < limitMin) {
    const messages = [
      `Je vois que tu as fini ce défi rapidement, mais je te recommande de prendre ton temps pour bien le comprendre. Prendre son temps est important pour assurer une compréhension solide 😉`,
      `Je remarque que tu as terminé ce défi rapidement, mais il est important de prendre le temps de comprendre chaque étape pour bien assimiler les concepts. N'hésite pas à revenir sur les parties qui te semblent floues 😄`,
      `Je constate que tu as terminé ce défi rapidement, mais il est important de ne pas sauter d'étapes pour comprendre pleinement chaque concept. Prends ton temps pour bien assimiler 💪`,
      `Je vois que tu as fini le défi rapidement, mais n'oublie pas que l'important n'est pas de finir vite, mais de bien comprendre 🙂 Prendre son temps peut aider à garantir une meilleure compréhension 😁`,
      `Je comprends que tu aies envie de finir le défi rapidement, mais il est important de prendre son temps pour bien comprendre 🤝`,
    ];

    return messages[getRandomIndex(5)];
  }

  if (timeSpent > limitMax) {
    const messages = [
      `Je vois que tu as pris plus de temps que prévu pour faire ce défi. Il est important de trouver un juste milieu entre la qualité et la rapidité de réalisation 😉`,
      `Je constate que tu as pris un peu plus de temps que prévu pour faire ce défi. N'oublie pas que la gestion du temps est importante pour être efficace 😄`,
      `Je remarque que tu as pris beaucoup de temps pour faire ce défi. Je te recommande de bien organiser ton temps pour être plus efficace. Prendre des pauses régulières peut également aider à garder un bon rythme 💪`,
      `Je vois que tu as mis beaucoup de temps pour terminer ce défi. Bien que la compréhension soit importante, il ne faut pas oublier que la rapidité de réalisation est également un facteur clé. Essaye de trouver un équilibre pour être efficace 😁`,
      `Je comprends que tu aies voulu prendre ton temps pour faire ce défi, mais n'oublie pas que le temps est précieux. Essaie de t'organiser pour être plus efficace 🤝`,
    ];

    return messages[getRandomIndex(5)];
  }

  const messages = [
    `Bravo tu es dans les temps ! C'est important de se fixer des objectifs et de travailler pour les atteindre. Continue sur cette lancée ! 😃`,
    `Félicitations tu es dans les temps ! Cela montre que tu es capable de gérer ton temps. Continue ! 😃`,
    `Super travail ! Tu es très efficace. C'est un bel accomplissement. Garde cette motivation pour les prochains défis, tu vas réussir ! 😃`,
    `Je suis impressionné par ton efficacité. Continue avec ce même état d'esprit ! 😃`,
    `Félicitations ! Ton efficacité montre que tu es organisé.e et que tu sais gérer ton temps. Continue avec cet état d'esprit positif ! 😃`,
  ];

  return messages[getRandomIndex(5)];
};

export const generateDifficultyFeedback = (
  level: ChallengeLevel,
  difficulty: ChallengeLevel
) => {
  if (level === ChallengeLevel.HARD) {
    if (difficulty === ChallengeLevel.EASY) {
      const messages = [
        `Respect 🫡 C'est un défi difficile mais tu l'as relevé avec brio. Attention quand-même à ne pas tomber dans la facilité, tu risques de faire preuve de négligence. Ce serait bien dommage 😄`,
        `WOW ! Félicitations ! Tu gères. Mais attention, même si cela te semble facile, ce serait dommage que tu prennes les choses à la légère 😃`,
      ];

      return messages[getRandomIndex(2)];
    }

    if (difficulty === ChallengeLevel.MEDIUM) {
      const messages = [
        `Pas si dur, n'est-ce pas ? 😄 Je te propose de continuer à t'entraîner même si tu n'as pas plus de difficulté que cela 😉`,
        `Bravo ! Le fait que tu n'aies pas eu beaucoup de difficulté pour relever le défi montre que tu as déjà le comportement adéquat. Continue à t'entraîner 😉`,
      ];

      return messages[getRandomIndex(2)];
    }

    const messages = [
      `Je m'attendais à ce tu sois hors de ta zone de confort et c'est le but 😉 Ne te décourage pas, l'entraînement sert à cela 😁 Tu vas progresser 💪`,
      `C'est normal si le défi t'a mis.e en difficulté 🙂 Ne t'en fais, en t'entraînant régulièrement, tu vas progresser 😉`,
    ];

    return messages[getRandomIndex(2)];
  }

  if (level === ChallengeLevel.MEDIUM) {
    if (difficulty === ChallengeLevel.EASY) {
      const messages = [
        `Pas si dur, n'est-ce pas ? 😄 Je te propose de continuer à t'entraîner même si tu n'as pas plus de difficulté que cela 😉`,
        `Bravo ! Le fait que tu n'aies pas eu beaucoup de difficulté pour relever le défi montre que tu as déjà le comportement adéquat. Continue à t'entraîner 😉`,
      ];

      return messages[getRandomIndex(2)];
    }

    if (difficulty === ChallengeLevel.HARD) {
      const messages = [
        `Je vois que tu as eu plus de difficulté que prévu. Ce n'est pas grave, tu as juste besoin de t'entraîner plus 🙂 Laisse passer un peu de temps et refais le défi, je suis sûr que tu feras mieux 😉 Courage !`,
        `Le défi a été difficile pour toi. Ne t'en fais pas, cela arrive 🙂 Repose-toi un peu et refais le 😉 Je suis sûr que tu seras plus à l'aise 💪`,
      ];

      return messages[getRandomIndex(2)];
    }

    const messages = [
      `Je trouve que tu t'en es bien sorti.e 🙂 Ce n'est pas un défi évident, continue de t'entraîner pour bien ancrer le comportement en toi 😉`,
      `Pas si évident n'est-ce pas ? 😃 Tu t'en es bien sorti.e 😉 Continue !`,
    ];

    return messages[getRandomIndex(2)];
  }

  if (difficulty === ChallengeLevel.MEDIUM) {
    const messages = [
      `Je vois que tu as eu plus de difficulté que prévu. Ce n'est pas grave, tu as juste besoin de t'entraîner plus 🙂 Laisse passer un peu de temps et refais le défi, je suis sûr que tu feras mieux 😉 Courage !`,
      `Le défi n'a été aussi simple pour toi. Ne t'en fais pas, cela arrive 🙂 Repose-toi un peu et refais le 😉 Je suis sûr que tu seras plus à l'aise 💪`,
    ];

    return messages[getRandomIndex(2)];
  }

  if (difficulty === ChallengeLevel.HARD) {
    const messages = [
      `Aïe... Tu as vraiment eu du mal avec ce défi. Ce n'est pas grave, ne te décourage pas 😉 Je te propose de laisser passer un peu de temps. Ensuite, relis les informations supplémentaires dans la section "En savoir plus" du défi et retente le coup 🙂 Tu verras, ce sera plus clair pour toi 😉`,
      `Le défi a vraiment été difficile pour toi. Ne t'en fais pas, c'est normal 🙂 Repose-toi pour le moment 😉 Quand tu seras prêt, relis les informations dans la section "En savoir plus" du défi et refais le 😉 Avec un regard nouveau, le défi te paraîtra plus clair 💪`,
    ];

    return messages[getRandomIndex(2)];
  }

  const messages = [
    `Bravo 🎉 Facile n'est-ce pas ? 😃 Normal, tu gères 👊🏽 Continue à t'entraîner !`,
    `Tu as trouvé cela facile ? Moi aussi 😝 Bravo, tu t'en es bien sorti.e 😉 Continue !`,
  ];

  return messages[getRandomIndex(2)];
};

const getRandomIndex = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getGeneralFeedback = (
  feedbackQuestions: FeedbackQuestion[],
  userResponses: UserFeedback[]
) => {
  const mcq = feedbackQuestions.filter(
    (f) => f.type === FeedbackQuestionType.choice
  );
  const mcqCodes = mcq.map((q) => q.code);

  const responses = userResponses.filter((r) =>
    mcqCodes.includes(r.questionCode)
  );

  const feedbacks = mcq.map((q) => {
    const response = responses.find((r) => r.questionCode === q.code);
    const choice = q.choices.find((c) => c.code === response?.response);

    return choice?.feedback;
  });

  return feedbacks.join(" ");
};

export const levelToText = (level: ChallengeLevel) => {
  if (level === ChallengeLevel.EASY) return "Facile";
  if (level === ChallengeLevel.MEDIUM) return "Moyen";
  return "Difficile";
};

export const levelToNumber = (level: ChallengeLevel) => {
  if (level === ChallengeLevel.EASY) return 1;
  if (level === ChallengeLevel.MEDIUM) return 2;
  return 3;
};
