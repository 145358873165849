const container = {
  initial: { scale: 0.5, opacity: 0, x: -50 },
  enter: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const leftToRight = {
  initial: { x: -50, opacity: 0 },
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 50,
    opacity: 0,
  },
};

const zoom = {
  initial: { scale: 0 },
  enter: {
    scale: 1,
  },
};

const rightToLeft = {
  initial: { x: 50, opacity: 0 },
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -50,
    opacity: 0,
  },
};

const bottomToTop = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 50,
    opacity: 0,
  },
};
const topToBottom = {
  initial: { y: -50, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.2 },
  },
};

export { container, leftToRight, bottomToTop, rightToLeft, topToBottom, zoom };
