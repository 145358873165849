import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "./screens/Login/Login";
import ValidityError from "./screens/ValidityError/ValidityError";

const AuthRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
      <Route path={`${path}/validity-error`}>
        <ValidityError />
      </Route>
      <Redirect to={`${path}/login`} />
    </Switch>
  );
};

export default AuthRoutes;
