const sendMessage = (action: string, payload?: any) => {
  const message = JSON.stringify({
    action,
    payload,
  });

  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(message);
  }
};

const hapticsImpact = () => {
  sendMessage("HAPTICS");
};

export { hapticsImpact };
