import { useEffect } from "react";

export const useHandleBodyScroll = (showModal: boolean) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);
};
