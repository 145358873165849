import { useParams } from "react-router-dom";
import "./styles.scss";
import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "modules/store";
import {
  leftToRight,
  rightToLeft,
  topToBottom,
} from "modules/shared/animations/animations.config";
import Button, { BackButton } from "modules/shared/components/Button/Button";
import {
  openSkillDetails,
  openSkillEvaluationResults,
} from "modules/shared/actions/navigation.action";
import { EvaluationQuestion } from "models/content-referential.model";
import { useEffect, useState } from "react";
import { EvaluationResponse } from "models/learner-progress.model";
import {
  sendFinalEvaluation,
  sendInitialEvaluation,
} from "modules/skill/actions/skill-evaluation.action";
import Render from "modules/shared/components/Render/Render";
import { SendingLoader } from "modules/shared/components/Loader/Loader";

interface Params {
  skillCode: string;
  isFinal?: string;
}

const SkillEvaluation: React.FC = () => {
  const { skillCode, isFinal } = useParams<Params>();
  const isFinalEvaluation = isFinal === "true";

  const dispatch = useAppDispatch();

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);

  const { data: learnerProgress } = useAppSelector(
    (state) => state.learnerProgress
  );
  const skillProgress = learnerProgress?.skillsProgress?.find(
    (sp) => sp.skillCode === skillCode
  );

  const [evaluationResponses, setEvaluationResponses] = useState<
    EvaluationResponse[]
  >([]);

  const [isSending, setIsSending] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const onGoBack = () => {
    dispatch(openSkillDetails(skillCode));
  };

  const onRate = (questionCode: string, rate: number) => {
    const responses = evaluationResponses.filter(
      (r) => r.questionCode !== questionCode
    );
    responses.push({ questionCode, rate });
    setEvaluationResponses(responses);
  };

  useEffect(() => {
    if (showValidation) {
      const timeout = setTimeout(() => {
        dispatch(
          isFinalEvaluation
            ? openSkillEvaluationResults(skillCode)
            : openSkillDetails(skillCode)
        );
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showValidation, skillCode, isFinalEvaluation, dispatch]);

  if (!skill || !skillProgress) return null;

  const validateEvaluation = async () => {
    if (evaluationResponses.length === skill?.evaluationQuestions?.length) {
      setIsSending(true);
      await dispatch(
        isFinalEvaluation
          ? sendFinalEvaluation({
              skillCode: skill.code,
              evaluationResponses: evaluationResponses,
            })
          : sendInitialEvaluation({
              skillCode: skill.code,
              evaluationResponses: evaluationResponses,
            })
      );
      setIsSending(false);
      setShowValidation(true);
    }
  };

  const evaluationQuestions = [...skill.evaluationQuestions];
  evaluationQuestions.sort((a, b) => a.order - b.order);

  const currentEvaluationResponses = isFinalEvaluation
    ? skillProgress.finalEvaluation
    : skillProgress.initialEvaluation;

  return (
    <AppContainer hideNavBar={isSending || showValidation}>
      <div
        className={`skill-evaluation-container ${
          isSending || showValidation ? "center" : ""
        }`}
      >
        <Render when={isSending || showValidation}>
          <SendingLoader
            isSending={isSending}
            showConfirmation={showValidation}
            sendingText="J'enregistre tes réponses..."
            confirmationText="Réponses enregistrées"
          />
        </Render>
        <Render when={!isSending && !showValidation}>
          <BackButton onClick={onGoBack} />
          <motion.div variants={leftToRight} className="header">
            <div className="title">Auto-évaluation</div>
          </motion.div>
          <div className="body">
            <motion.div variants={topToBottom} className="instruction">
              Es-tu d'accord avec les affirmations suivantes ?
            </motion.div>
            <div className="evaluations">
              {evaluationQuestions.map((question) => (
                <EvaluationItem
                  key={question.code}
                  evaluationQuestion={question}
                  evaluationResponse={currentEvaluationResponses?.responses?.find(
                    (r) => r.questionCode === question.code
                  )}
                  onRate={onRate}
                />
              ))}
            </div>
            <Render when={!currentEvaluationResponses}>
              <div className="validation">
                <Button text="Valider" onClick={validateEvaluation} />
              </div>
            </Render>
          </div>
        </Render>
      </div>
    </AppContainer>
  );
};

export default SkillEvaluation;

interface EvaluationItemProps {
  evaluationQuestion: EvaluationQuestion;
  evaluationResponse?: EvaluationResponse;
  onRate: (questionCode: string, rate: number) => void;
}

const EvaluationItem: React.FC<EvaluationItemProps> = ({
  evaluationQuestion,
  evaluationResponse,
  onRate,
}) => {
  const evaluate = (rate: number) => {
    if (!evaluationResponse) onRate(evaluationQuestion.code, rate);
  };

  return (
    <motion.div variants={leftToRight} className="evaluation-item">
      <div className="question">
        {evaluationQuestion.order}. {evaluationQuestion.question}
      </div>
      <Rating rating={evaluationResponse?.rate} onRate={evaluate} />
    </motion.div>
  );
};

interface RatingProps {
  rating?: number;
  onRate: (rate: number) => void;
}

const Rating: React.FC<RatingProps> = ({ rating, onRate }) => {
  const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [currentRating, setCurrentRating] = useState(rating);

  const setRate = (rate: number) => {
    if (!rating) {
      setCurrentRating(rate);
      onRate(rate);
    }
  };

  return (
    <div className="rating">
      <div className="range">
        {ratings.map((value) => (
          <div
            key={`rating-${value}`}
            className={`item ${currentRating === value ? "active" : ""}`}
            onClick={() => setRate(value)}
          >
            <span>{value}</span>
          </div>
        ))}
      </div>
      <div className="label">
        <motion.div variants={leftToRight}>Pas du tout d'accord</motion.div>
        <motion.div variants={rightToLeft}>Tout à fait d'accord</motion.div>
      </div>
    </div>
  );
};
