import { useHistory, useParams } from "react-router-dom";
import "./styles.scss";
import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import { useAppDispatch, useAppSelector } from "modules/store";
import Button, { BackButton } from "modules/shared/components/Button/Button";
import { motion } from "framer-motion";
import {
  bottomToTop,
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import MarkdownReader from "modules/shared/components/MarkdownReader/MarkdownReader";
import infoAnimationData from "assets/lottie/info.json";
import muscleAnimationData from "assets/lottie/muscle.json";
import Lottie from "react-lottie";
import { FiCheckCircle, FiInfo } from "react-icons/fi";
import { useState } from "react";
import Render from "modules/shared/components/Render/Render";
import Modal from "modules/shared/components/Modal/Modal";
import { useHandleBodyScroll } from "modules/shared/hooks/useHandleBodyScroll";
import FloatingButtonContainer from "modules/shared/components/FloatingButtonContainer/FloatingButtonContainer";
import {
  startNewChallenge,
  updateCompletedTasks,
} from "modules/challenge/actions/learner-challenge.action";
import { SpinnerLoader } from "modules/shared/components/Loader/Loader";
import FeedbackViewer from "modules/challenge/components/FeedbackViewer/FeedbackViewer";
import { ChallengeLevel } from "models/content-referential.model";
import { getAppContext } from "utils/app-context.util";

interface Params {
  challengeCode: string;
  skillCode: string;
}

const ChallengeDetails: React.FC = () => {
  const history = useHistory();
  const { challengeCode, skillCode } = useParams<Params>();

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);
  const challenge = skill?.challenges?.find((c) => c.code === challengeCode);

  const { learnerChallenges } = useAppSelector((state) => state.challenge);
  const learnerChallenge = learnerChallenges.find(
    (lc) => lc.skillCode === skillCode && lc.challengeCode === challengeCode
  );
  const numberOfCompletedTasks = learnerChallenge?.completedTasks?.length ?? 0;

  const dispatch = useAppDispatch();

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showStartedModal, setShowStartedModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const onGoBack = () => {
    history.push(`/skill/details/${skillCode}`);
  };

  const openContent = () => {
    setShowDetailsModal(true);
  };

  const startChallenge = async () => {
    setShowStartedModal(true);
    setShowLoader(true);
    await dispatch(startNewChallenge({ skillCode, challengeCode }));
    setShowLoader(false);
  };

  useHandleBodyScroll(showDetailsModal || showStartedModal);

  if (!challenge || !skill) return null;

  const tasks = [...challenge.tasks];
  tasks.sort((a, b) => a.order - b.order);

  const validateChallenge = () => {
    if (numberOfCompletedTasks < challenge.tasks.length) {
      setShowStartedModal(true);
    } else {
      history.push(`/challenge/feedback/${learnerChallenge?.id}`);
    }
  };

  const checkTask = async (taskCode: string) => {
    if (learnerChallenge) {
      const checkedTasks = learnerChallenge.completedTasks ?? [];
      const isAlreadyChecked =
        checkedTasks.findIndex((t) => t === taskCode) > -1;

      if (isAlreadyChecked) {
        dispatch(
          updateCompletedTasks({
            learnerChallengeId: learnerChallenge.id,
            tasks: checkedTasks.filter((t) => t !== taskCode),
          })
        );
      } else {
        dispatch(
          updateCompletedTasks({
            learnerChallengeId: learnerChallenge.id,
            tasks: [...checkedTasks, taskCode],
          })
        );
      }
    }
  };

  const seeResults = () => {
    history.push(`/challenge/results/${learnerChallenge?.id}/true`);
  };

  return (
    <>
      <AppContainer hideNavBar>
        <div
          className={`challenge-details-container ${
            learnerChallenge?.completed && "no-padding-bottom"
          }`}
        >
          <BackButton onClick={onGoBack} />
          <MoreInfo onClick={openContent} />
          <div className="presentation">
            <motion.div variants={topToBottom} className="title">
              {challenge.title}
            </motion.div>
            <motion.div variants={leftToRight} className="duration">
              Durée: {challenge.duration} min(s)
            </motion.div>
            <Render
              when={
                learnerChallenge !== undefined && !learnerChallenge.completed
              }
            >
              <motion.div variants={leftToRight} className="in-progress">
                En cours
              </motion.div>
            </Render>
            <Render when={learnerChallenge?.completed ?? false}>
              <motion.div variants={leftToRight} className="completed">
                Terminé
                <div className="icon">
                  <FiCheckCircle />
                </div>
              </motion.div>
            </Render>
            <motion.div variants={leftToRight} className="image">
              <img src={challenge.imageUrl} alt="challenge" />
            </motion.div>
          </div>
          <div className="description">
            <motion.div variants={leftToRight} className="title">
              Instructions
            </motion.div>
            <motion.div variants={topToBottom} className="content">
              <MarkdownReader markdown={challenge.description} />
            </motion.div>
            <motion.div variants={bottomToTop} className="read-more">
              <div className="icon">
                <FiInfo />
              </div>
              <div className="text" onClick={openContent}>
                En savoir plus
              </div>
            </motion.div>
          </div>
          <Render when={learnerChallenge !== undefined}>
            <div className="tasks">
              <div className="title">Tâches à faire</div>
              {tasks.map((task) => (
                <TaskItem
                  key={task.code}
                  text={task.instruction}
                  isChecked={
                    learnerChallenge?.completedTasks?.find(
                      (t) => t === task.code
                    ) !== undefined
                  }
                  onCheck={() => checkTask(task.code)}
                />
              ))}
            </div>
          </Render>
          <Render when={learnerChallenge?.completed === true}>
            <FeedbackViewer
              feedbackQuestions={challenge.feedbackQuestions}
              userFeedbacks={learnerChallenge?.feedback?.userFeedbacks ?? []}
              timeSpent={learnerChallenge?.feedback?.timeSpent ?? 0}
              difficulty={
                learnerChallenge?.feedback?.difficulty ?? ChallengeLevel.EASY
              }
              completionDate={
                learnerChallenge?.feedback?.completionDate ?? new Date()
              }
            />
            <div className="see-results-button">
              <Button
                className="cta"
                text="Voir les retours"
                onClick={seeResults}
              />
            </div>
          </Render>
          <FloatingButtonContainer>
            <Render when={!learnerChallenge}>
              <Button text="Je relève le défi" onClick={startChallenge} />
            </Render>
            <Render
              when={
                learnerChallenge !== undefined && !learnerChallenge.completed
              }
            >
              <Button
                text="Je valide mon défi"
                disabled={numberOfCompletedTasks < challenge.tasks.length}
                onClick={validateChallenge}
              />
            </Render>
          </FloatingButtonContainer>
        </div>
      </AppContainer>
      <Render when={showDetailsModal}>
        <Modal onClose={() => setShowDetailsModal(false)}>
          <div className="challenge-content-modal">
            <MarkdownReader markdown={challenge.content} />
          </div>
        </Modal>
      </Render>
      <Render when={showStartedModal}>
        <Modal
          hideCloseButton={showLoader}
          onClose={() => setShowStartedModal(false)}
        >
          <StartedChallengeModalContent
            isLoading={showLoader}
            close={() => setShowStartedModal(false)}
          />
        </Modal>
      </Render>
    </>
  );
};

export default ChallengeDetails;

const InfoOptions = {
  loop: true,
  autoplay: true,

  animationData: infoAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const MoreInfo: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { topInset } = getAppContext();

  return (
    <div
      className="more-info-button"
      style={{ marginTop: topInset }}
      onClick={onClick}
    >
      <div className="lottie">
        <Lottie options={InfoOptions} />
      </div>
      <div className="text">En savoir plus</div>
    </div>
  );
};

interface TaskItemProps {
  text: string;
  isChecked?: boolean;
  disabled?: boolean;
  onCheck: () => void;
}

const TaskItem: React.FC<TaskItemProps> = ({
  text,
  isChecked,
  disabled,
  onCheck,
}) => {
  return (
    <motion.div variants={leftToRight} className="task-item">
      <div className="checkbox">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={onCheck}
        />
      </div>
      <div className="text">{text}</div>
    </motion.div>
  );
};

const MuscleOptions = {
  loop: true,
  autoplay: true,

  animationData: muscleAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StartedChallengeModalContentProps {
  isLoading: boolean;
  close: () => void;
}

const StartedChallengeModalContent: React.FC<
  StartedChallengeModalContentProps
> = ({ isLoading, close }) => {
  return (
    <div className="challenge-started-modal">
      <Render when={isLoading}>
        <SpinnerLoader />
      </Render>
      <Render when={!isLoading}>
        <div className="animation">
          <div className="lottie">
            <Lottie options={MuscleOptions} />
          </div>
        </div>
        <motion.div variants={topToBottom} className="title">
          Défi accepté !
        </motion.div>
        <motion.div variants={leftToRight} className="start">
          Ton défi commence maintenant.
        </motion.div>
        <motion.div variants={leftToRight} className="step">
          <span>1.</span>Suis les instructions 📜
        </motion.div>
        <motion.div variants={leftToRight} className="step">
          <span>2.</span>Termine les tâches ✅
        </motion.div>
        <motion.div variants={leftToRight} className="step">
          <span>3.</span>Valide ton défi 🚀
        </motion.div>
        <motion.div variants={leftToRight} className="reminder">
          N'oublie pas que tu as accès à des{" "}
          <span>informations supplémentaires</span> pour t'aider.
        </motion.div>
        <motion.div variants={leftToRight} className="reminder">
          Il te suffit de cliquer sur "<span>En savoir plus</span>"{" "}
          <span>en haut à droite 👆🏽😉</span>
        </motion.div>
        <div className="button">
          <Button className="ok" text="D'accord !" onClick={close} />
        </div>
      </Render>
    </div>
  );
};
