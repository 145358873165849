import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import "./styles.scss";
import Avatar from "modules/shared/components/Avatar/Avatar";
import { useAppSelector } from "modules/store";
import { motion } from "framer-motion";
import { topToBottom } from "modules/shared/animations/animations.config";
import SkillCard from "modules/shared/components/SkillCard/SkillCard";

const Home: React.FC = () => {
  const { data: learnerInformation } = useAppSelector(
    (state) => state.learnerInformation
  );
  const { program } = useAppSelector((state) => state.contentReferential);

  return (
    <AppContainer>
      <div className="home-container">
        <Avatar
          message={`À toi de jouer ${learnerInformation?.firstName} 😃`}
        />
        <div className="skills">
          <motion.div variants={topToBottom} className="title">
            Compétences
          </motion.div>
          {program && (
            <div className="list">
              {program.skills.map((skill) => (
                <SkillCard key={skill.code} skill={skill} />
              ))}
            </div>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default Home;
