const paramsToObject = (entries: any) => {
  const result: any = {};
  for (let entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
};

export const initAppContext = () => {
  const params = new URLSearchParams(window.location.search.substring(1));
  const entries = params.entries();

  var parameters = paramsToObject(entries);

  // @ts-ignore
  window.appContext = {
    topInset: parameters.topInset > 0 ? `${parameters.topInset}px` : undefined,
    bottomInset:
      parameters.bottomInset > 0 ? `${parameters.bottomInset}px` : undefined,
  };
};

interface AppContext {
  topInset: string;
  bottomInset: string;
}

export function getAppContext(): AppContext {
  // @ts-ignore
  return window.appContext;
}
