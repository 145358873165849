import { motion } from "framer-motion";
import "./styles.scss";
import { container } from "modules/shared/animations/animations.config";
import { FiX } from "react-icons/fi";
import Render from "../Render/Render";

interface ModalProps {
  children: React.ReactNode;
  hideCloseButton?: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  hideCloseButton,
  onClose,
}) => {
  return (
    <motion.div
      variants={container}
      initial="initial"
      animate="enter"
      exit="exit"
      className="modal-component"
    >
      <div className="container">
        <Render when={!hideCloseButton}>
          <div className="close" onClick={onClose}>
            <div className="icon">
              <FiX />
            </div>
          </div>
        </Render>
        <div className="body">{children}</div>
      </div>
    </motion.div>
  );
};

export default Modal;
