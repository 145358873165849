import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthenticationData, LoginStatus } from "models/authentication.model";
import {
  clearSessionState,
  updateAuthenticationData,
  updateLoginStatus,
} from "modules/auth/store/session.reducer";
import { ThunkApi } from "modules/store";
import { history } from "./shared/services/routing/history";
import { checkValidity } from "./shared/services/learner/learner.service";
import { clearAuthData } from "./shared/services/authentication/authentication.service";
import { clearLearnerInformation } from "./shared/stores/learner/learner-information.reducer";
import { loadLearnerInformation } from "./shared/actions/learner/learner-information.action";
import { loadContentReferential } from "./shared/actions/content-referential/content-referential.action";
import { clearLearnerProgress } from "./shared/stores/learner/learner-progress.reducer";
import { loadLearnerProgress } from "./shared/actions/learner/learner-progress.action";
import { clearContentReferential } from "./shared/stores/content-referential/content-referential.reducer";
import { loadLearnerChallenges } from "./challenge/actions/learner-challenge.action";

export const initializeUserSession = createAsyncThunk<
  void,
  {
    authenticationData: AuthenticationData;
    shouldRequestPushNotificationToken: boolean;
  },
  ThunkApi
>("initialize-session", async ({ authenticationData }, thunkApi) => {
  const { dispatch } = thunkApi;

  const currentRoute = window.location.pathname;
  if (currentRoute.includes("/privacy-policy")) return;

  if (authenticationData.token === undefined) {
    dispatch(clearAuthenticationData());
    history.replace("/auth");
    return;
  }

  const accountValidity = await checkValidity();

  if (!accountValidity.data?.validity) {
    dispatch(clearAuthenticationData());
    history.push("/auth/validity-error");
    return;
  }

  await Promise.all([
    dispatch(updateLoginStatus(LoginStatus.succeed)),
    dispatch(updateAuthenticationData(authenticationData)),
  ]);

  await dispatch(loadLearnerInformation());
  await dispatch(loadContentReferential());
  await dispatch(loadLearnerProgress());
  await dispatch(loadLearnerChallenges());

  if (currentRoute.includes("/auth")) {
    history.replace("/home");
  }
});

const clearAuthenticationData = createAsyncThunk<void, void, ThunkApi>(
  "clear-auth-data",
  async (_, { dispatch }) => {
    dispatch(clearSessionState());
    clearAuthData();
  }
);

const clearLearnerData = createAsyncThunk<void, void, ThunkApi>(
  "clear-learner-data",
  async (_, { dispatch }) => {
    dispatch(clearLearnerInformation());
    dispatch(clearLearnerProgress());
    dispatch(clearContentReferential());
  }
);

export const logOut = createAsyncThunk<void, void, ThunkApi>(
  "logout",
  async (_, { dispatch }) => {
    dispatch(clearAuthenticationData());
    dispatch(clearLearnerData());

    history.replace("/auth");
  }
);
