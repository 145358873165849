import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationData, LoginStatus } from "models/authentication.model";

interface SessionState {
  loginStatus?: LoginStatus;
  authenticationData?: AuthenticationData;
}

const initialState: SessionState = {
  loginStatus: undefined,
  authenticationData: undefined,
};

const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateLoginStatus: (state, action: PayloadAction<LoginStatus>) => {
      state.loginStatus = action.payload;
    },
    updateAuthenticationData: (
      state,
      action: PayloadAction<AuthenticationData>
    ) => {
      state.authenticationData = action.payload;
    },
    clearSessionState: (state) => {
      state.loginStatus = undefined;
      state.authenticationData = undefined;
    },
  },
});

export const {
  updateLoginStatus,
  updateAuthenticationData,
  clearSessionState,
} = SessionSlice.actions;

export default SessionSlice.reducer;
