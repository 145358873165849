import "./styles.scss";

interface DecorationProps {
  className?: string;
}

export const CircleDecoration: React.FC<DecorationProps> = ({ className }) => {
  return (
    <div className={`circle-decoration-component ${className}`}>
      <div className="circle"></div>
    </div>
  );
};

export const SquareDecoration: React.FC<DecorationProps> = ({ className }) => {
  return <div className={`square-decoration-component ${className}`}></div>;
};

export const Wave: React.FC = () => {
  return (
    <div className="wave-component">
      <div className="wave"></div>
    </div>
  );
};
