import AuthContainer from "modules/auth/components/AuthContainer/AuthContainer";
import TextInput from "modules/shared/components/TextInput/TextInput";
import { useEffect, useState } from "react";
import "./styles.scss";
import Button from "modules/shared/components/Button/Button";
import Lottie from "react-lottie";
import loginHelloAnimationData from "assets/lottie/login-hello.json";
import { useAppDispatch, useAppSelector } from "modules/store";
import { loginUser } from "modules/auth/actions/auth.action";
import Render from "modules/shared/components/Render/Render";
import { LoginStatus } from "models/authentication.model";
import { SpinnerLoader } from "modules/shared/components/Loader/Loader";

const LoginHelloOptions = {
  loop: true,
  autoplay: true,

  animationData: loginHelloAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loginStatus } = useAppSelector((state) => state.session);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const login = async () => {
    if (username.length > 0 && password.length > 0) {
      setIsLoading(true);
      await dispatch(loginUser({ login: username, password }));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loginStatus === LoginStatus.failed) setShowError(true);
    if (loginStatus === LoginStatus.succeed) setShowError(false);
  }, [loginStatus]);

  return (
    <AuthContainer>
      <div className="login-form">
        <Render when={isLoading}>
          <div className="loader">
            <SpinnerLoader />
          </div>
        </Render>
        <Render when={!isLoading}>
          <div className="animation">
            <div className="lottie">
              <Lottie options={LoginHelloOptions} />
            </div>
          </div>
          <div className="label">Nom d'utilisateur</div>
          <TextInput
            className="input"
            text={username}
            onTextChange={setUsername}
          />
          <div className="label">Mot de passe</div>
          <TextInput
            className="input"
            text={password}
            type="password"
            onTextChange={setPassword}
          />
          <Render when={showError}>
            <div className="error">
              Nom d'utilisateur ou mot de passe incorrect
            </div>
          </Render>
          <div className="cta">
            <Button text="Je me connecte" onClick={login} />
          </div>
        </Render>
      </div>
    </AuthContainer>
  );
};

export default Login;
