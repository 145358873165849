import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { getLearnerProgress } from "modules/shared/services/learner/learner.service";
import { setLearnerProgress } from "modules/shared/stores/learner/learner-progress.reducer";
import { ThunkApi } from "modules/store";

export const loadLearnerProgress = createAsyncThunk<void, void, ThunkApi>(
  "learner/learner-progress",
  async (_, { dispatch }) => {
    const { status, data } = await getLearnerProgress();

    if (status === ApiOperationStatus.Success && data) {
      dispatch(setLearnerProgress(data));
    }
  }
);
