import Render from "../Render/Render";
import { FiEye } from "react-icons/fi";
import "./styles.scss";
import { useState } from "react";
import { motion } from "framer-motion";
import { leftToRight } from "modules/shared/animations/animations.config";

interface TextInputProps {
  className?: string;
  text?: string;
  type?: "text" | "password" | "number";
  onTextChange: (text: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  className,
  text,
  type = "text",
  onTextChange,
}) => {
  const [show, setShow] = useState(false);

  return (
    <motion.div
      variants={leftToRight}
      className={`text-input-component ${className}`}
    >
      <input
        type={show ? "text" : type}
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
      />
      <Render when={type === "password"}>
        <FiEye className="show-password" onClick={() => setShow(!show)} />
      </Render>
    </motion.div>
  );
};

export default TextInput;
