import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import "./styles.scss";
import { motion } from "framer-motion";
import {
  leftToRight,
  topToBottom,
} from "modules/shared/animations/animations.config";
import { useAppDispatch, useAppSelector } from "modules/store";
import { FiKey, FiStar } from "react-icons/fi";
import { Skill } from "models/content-referential.model";
import { SkillProgress } from "models/learner-progress.model";
import { aggregatePercentage } from "utils/number.util";
import { openSkillDetails } from "modules/shared/actions/navigation.action";
import Avatar from "modules/shared/components/Avatar/Avatar";

const Progress: React.FC = () => {
  const { data: learnerProgress } = useAppSelector(
    (state) => state.learnerProgress
  );

  const { program } = useAppSelector((state) => state.contentReferential);

  const dispatch = useAppDispatch();

  const onClickSkill = (skillCode: string) => {
    dispatch(openSkillDetails(skillCode));
  };

  return (
    <AppContainer>
      <div className="progress-container">
        <Avatar message="Voici tes statistiques 💪" />
        <motion.div variants={topToBottom} className="stats">
          <Stat
            icon={<FiKey />}
            text={`${learnerProgress?.numberOfCompletedChallenges}/${learnerProgress?.numberOfChallenges}`}
            label="défi(s) terminé(s)"
          />
          <Stat
            icon={<FiStar />}
            text={`${learnerProgress?.numberOfCompletedSkills}/${learnerProgress?.numberOfSkills}`}
            label="compétence(s) travaillée(s)"
          />
        </motion.div>
        <div className="skills">
          {learnerProgress?.skillsProgress.map((skillProgress) => (
            <SkillProgressCard
              key={skillProgress.skillCode}
              skill={
                program!.skills.find((s) => s.code === skillProgress.skillCode)!
              }
              progress={skillProgress}
              onClick={() => onClickSkill(skillProgress.skillCode)}
            />
          ))}
        </div>
      </div>
    </AppContainer>
  );
};

export default Progress;

interface StatProps {
  icon: React.ReactNode;
  text: string;
  label: string;
}

const Stat: React.FC<StatProps> = ({ icon, text, label }) => {
  return (
    <div className="stat">
      <div className="icon">{icon}</div>
      <div className="text">{text}</div>
      <div className="label">{label}</div>
    </div>
  );
};

interface SkillProgressProps {
  skill: Skill;
  progress: SkillProgress;
  onClick: () => void;
}

const SkillProgressCard: React.FC<SkillProgressProps> = ({
  skill,
  progress,
  onClick,
}) => {
  const percentage = aggregatePercentage(
    progress.numberOfCompletedChallenges,
    progress.numberOfChallenges
  );

  return (
    <motion.div
      variants={leftToRight}
      className="skill-progress"
      onClick={onClick}
    >
      <div className="left">
        <div className="title">{skill.title}</div>
        <div className="theme" style={{ color: skill.skillTheme.color }}>
          {skill.skillTheme.title}
        </div>
      </div>
      <div className="right">
        <div
          className="progress"
          style={{ borderColor: skill.skillTheme.color }}
        >
          <div className="value" style={{ color: skill.skillTheme.color }}>
            {percentage}%
          </div>
        </div>
      </div>
    </motion.div>
  );
};
