import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApi } from "modules/store";
import {
  getLearnerChallenges,
  setCompletedTasks,
  setFeedback,
  startChallenge,
} from "../services/learner-challenge.service";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { setLearnerChallenges } from "../store/challenge.reducer";
import { UserFeedback } from "models/learner-challenge.model";
import { ChallengeLevel } from "models/content-referential.model";
import { loadLearnerProgress } from "modules/shared/actions/learner/learner-progress.action";

export const loadLearnerChallenges = createAsyncThunk<void, void, ThunkApi>(
  "challenge/learner-challenges",
  async (_, { dispatch }) => {
    const { status, data } = await getLearnerChallenges();

    if (status === ApiOperationStatus.Success && data) {
      dispatch(setLearnerChallenges(data));
    }
  }
);

export const startNewChallenge = createAsyncThunk<
  void,
  { skillCode: string; challengeCode: string },
  ThunkApi
>(
  "challenge/learner-challenges",
  async ({ skillCode, challengeCode }, { dispatch }) => {
    const { status, data } = await startChallenge(skillCode, challengeCode);

    if (status === ApiOperationStatus.Success && data) {
      await dispatch(loadLearnerChallenges());
      await dispatch(loadLearnerProgress());
    }
  }
);

export const updateCompletedTasks = createAsyncThunk<
  void,
  { learnerChallengeId: number; tasks: string[] },
  ThunkApi
>(
  "challenge/learner-challenges",
  async ({ learnerChallengeId, tasks }, { dispatch }) => {
    const { status, data } = await setCompletedTasks(learnerChallengeId, tasks);

    if (status === ApiOperationStatus.Success && data) {
      await dispatch(loadLearnerChallenges());
    }
  }
);

export const completeChallenge = createAsyncThunk<
  void,
  {
    learnerChallengeId: number;
    userFeedbacks: UserFeedback[];
    timeSpent: number;
    difficulty: ChallengeLevel;
  },
  ThunkApi
>(
  "challenge/learner-challenges",
  async (
    { learnerChallengeId, userFeedbacks, timeSpent, difficulty },
    { dispatch }
  ) => {
    const { status, data } = await setFeedback(
      learnerChallengeId,
      userFeedbacks,
      timeSpent,
      difficulty
    );

    if (status === ApiOperationStatus.Success && data) {
      await dispatch(loadLearnerChallenges());
      await dispatch(loadLearnerProgress());
    }
  }
);
