import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApi } from "modules/store";
import {
  setFinalEvaluation,
  setInitialEvaluation,
} from "../services/skill-evaluation.service";
import { EvaluationResponse } from "models/learner-progress.model";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { loadLearnerProgress } from "modules/shared/actions/learner/learner-progress.action";

export const sendInitialEvaluation = createAsyncThunk<
  void,
  { skillCode: string; evaluationResponses: EvaluationResponse[] },
  ThunkApi
>(
  "skill/skill-evaluation/initial",
  async ({ skillCode, evaluationResponses }, { dispatch }) => {
    const { status } = await setInitialEvaluation(
      skillCode,
      evaluationResponses
    );

    if (status === ApiOperationStatus.Success) {
      await dispatch(loadLearnerProgress());
    }
  }
);

export const sendFinalEvaluation = createAsyncThunk<
  void,
  { skillCode: string; evaluationResponses: EvaluationResponse[] },
  ThunkApi
>(
  "skill/skill-evaluation/final",
  async ({ skillCode, evaluationResponses }, { dispatch }) => {
    const { status } = await setFinalEvaluation(skillCode, evaluationResponses);

    if (status === ApiOperationStatus.Success) {
      await dispatch(loadLearnerProgress());
    }
  }
);
