export interface Challenge {
  title: string;
  code: string;
  order: number;
  duration: number;
  level: ChallengeLevel;
  imageUrl: string;
  description: string;
  content: string;
  feedbackQuestions: FeedbackQuestion[];
  tasks: Task[];
  goal: string;
}

export enum ChallengeLevel {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
}

export interface FeedbackQuestion {
  question: string;
  code: string;
  type: FeedbackQuestionType;
  choices: FeedbackQuestionChoice[];
}

export enum FeedbackQuestionType {
  text = "text",
  numeric = "numeric",
  choice = "choice",
}

export interface FeedbackQuestionChoice {
  text: string;
  code: string;
  order: number;
  feedback: string;
}

export interface Task {
  instruction: string;
  code: string;
  order: number;
}

export interface Skill {
  title: string;
  code: string;
  order: number;
  imageUrl: string;
  shortDescription: string;
  longDescription: string;
  evaluationQuestions: EvaluationQuestion[];
  skillTheme: SkillTheme;
  challenges: Challenge[];
}

export interface EvaluationQuestion {
  question: string;
  order: number;
  code: string;
  label: string;
}

export interface SkillTheme {
  title: string;
  code: string;
  color: string;
}

export interface Program {
  code: string;
  description: string;
  skills: Skill[];
}
