import config from "config";
import { ApiOperationResult } from "models/api-operation-result.model";
import { ChallengeLevel } from "models/content-referential.model";
import { LearnerChallenge, UserFeedback } from "models/learner-challenge.model";
import { getCurrentUserToken } from "modules/shared/services/authentication/authentication.service";
import {
  GET,
  POST,
  PUT,
} from "modules/shared/services/http-operations/http-operations";

const LEARNER_CHALLENGE_ROUTE = `${config.apiUrl}/challenge`;

export const getLearnerChallenges = async (): Promise<
  ApiOperationResult<LearnerChallenge[]>
> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_CHALLENGE_ROUTE}/all`;
  const result = await GET<LearnerChallenge[]>(url, token);
  return result;
};

export const startChallenge = async (
  skillCode: string,
  challengeCode: string
): Promise<ApiOperationResult<LearnerChallenge>> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_CHALLENGE_ROUTE}/start/${skillCode}/${challengeCode}`;
  const result = await POST<{}, LearnerChallenge>(url, token);
  return result;
};

export const setCompletedTasks = async (
  learnerChallengeId: number,
  tasks: string[]
): Promise<ApiOperationResult<LearnerChallenge>> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_CHALLENGE_ROUTE}/completedTasks/${learnerChallengeId}`;
  const result = await PUT<{}, LearnerChallenge>(url, token, tasks);
  return result;
};

export const setFeedback = async (
  learnerChallengeId: number,
  userFeedbacks: UserFeedback[],
  timeSpent: number,
  difficulty: ChallengeLevel
): Promise<ApiOperationResult<LearnerChallenge>> => {
  const token = getCurrentUserToken();
  const url = `${LEARNER_CHALLENGE_ROUTE}/completeChallenge/${learnerChallengeId}`;
  const result = await PUT<{}, LearnerChallenge>(url, token, {
    userFeedbacks,
    timeSpent,
    difficulty,
  });
  return result;
};
