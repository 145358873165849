import { motion } from "framer-motion";
import "./styles.scss";
import {
  bottomToTop,
  container,
} from "modules/shared/animations/animations.config";
import { useHistory, useLocation } from "react-router-dom";
import { FiBarChart2, FiHome, FiUser } from "react-icons/fi";
import logo from "assets/images/logo.png";
import { useEffect, useState } from "react";
import { Wave } from "../Decoration/Decoration";
import { getAppContext } from "utils/app-context.util";

interface AppContainerProps {
  children: React.ReactNode;
  hideNavBar?: boolean;
}

const AppContainer: React.FC<AppContainerProps> = ({
  children,
  hideNavBar = false,
}) => {
  const pathname = useLocation();
  const [activeRoute, setActiveRoute] = useState(pathname.pathname);

  const history = useHistory();
  history.listen((location) => {
    const appRoutes = ["/home", "/progress", "/profile"];

    if (
      appRoutes.some(
        (item) => location.pathname?.toLocaleLowerCase().indexOf(item) > -1
      )
    ) {
      setActiveRoute(location.pathname);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { topInset, bottomInset } = getAppContext();

  return (
    <motion.div
      variants={container}
      initial="initial"
      animate="enter"
      exit="exit"
      className={`app-container ${hideNavBar ? "hide-nav-bar" : ""}`}
      style={{ marginTop: topInset, paddingBottom: bottomInset }}
    >
      <div className="app-container-card">{children}</div>
      <motion.div
        variants={bottomToTop}
        className="tab"
        style={{ paddingBottom: bottomInset }}
      >
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <TabItem
          text="Statistiques"
          icon={<FiBarChart2 />}
          isActive={activeRoute === "/progress"}
          onClick={() => history.push("/progress")}
        />
        <TabItem
          text="Accueil"
          icon={<FiHome />}
          isActive={activeRoute === "/home"}
          onClick={() => history.push("/home")}
          isPrincipal
        />
        <TabItem
          text="Profil"
          icon={<FiUser />}
          isActive={activeRoute === "/profile"}
          onClick={() => history.push("/profile")}
        />
      </motion.div>
      <Wave />
    </motion.div>
  );
};

export default AppContainer;

interface TabItemProps {
  text: string;
  icon: React.ReactNode;
  isActive: boolean;
  isPrincipal?: boolean;
  onClick: () => void;
}

const TabItem: React.FC<TabItemProps> = ({
  icon,
  text,
  isActive,
  isPrincipal,
  onClick,
}) => {
  return (
    <div
      className={`tab-item ${isPrincipal ? "principal" : ""} ${
        isActive ? "active" : ""
      }`}
      onClick={onClick}
    >
      <div className="icon">{icon}</div>
      <div className="text">{text}</div>
    </div>
  );
};
