import { useHistory, useParams } from "react-router-dom";
import "./styles.scss";
import AppContainer from "modules/shared/components/AppContainer/AppContainer";
import { useAppDispatch, useAppSelector } from "modules/store";
import {
  FiActivity,
  FiCheckCircle,
  FiChevronRight,
  FiClock,
  FiFlag,
  FiKey,
  FiLock,
} from "react-icons/fi";
import { formatMinute } from "utils/date.util";
import { aggregateSkillDuration } from "modules/shared/services/content-referential/skill.service";
import { motion } from "framer-motion";
import {
  leftToRight,
  rightToLeft,
  topToBottom,
  bottomToTop,
} from "modules/shared/animations/animations.config";
import { useState } from "react";
import MarkdownReader from "modules/shared/components/MarkdownReader/MarkdownReader";
import Render from "modules/shared/components/Render/Render";
import ChallengeCard from "modules/shared/components/ChallengeCard/ChallengeCard";
import { BackButton } from "modules/shared/components/Button/Button";
import { openSkillEvaluationResults } from "modules/shared/actions/navigation.action";

interface Params {
  skillCode: string;
}

enum TAB {
  DETAILS,
  CHALLENGES,
}

const SkillDetails: React.FC = () => {
  const history = useHistory();
  const { skillCode } = useParams<Params>();

  const dispatch = useAppDispatch();

  const { program } = useAppSelector((state) => state.contentReferential);
  const skill = program?.skills?.find((s) => s.code === skillCode);

  const { data: learnerProgress } = useAppSelector(
    (state) => state.learnerProgress
  );
  const skillProgress = learnerProgress?.skillsProgress?.find(
    (sp) => sp.skillCode === skillCode
  );

  const [activeTab, setActiveTab] = useState<TAB>(TAB.CHALLENGES);

  const onGoBack = () => {
    history.push("/home");
  };

  const openEvaluation = (isFinal = false) => {
    let evaluationRoute = `/skill/evaluation/${skill?.code}`;
    if (isFinal) evaluationRoute = `${evaluationRoute}/${isFinal}`;
    history.push(evaluationRoute);
  };

  const openResults = () => {
    dispatch(openSkillEvaluationResults(skillCode));
  };

  if (!skill || !skillProgress) return null;

  let challenges = [...skill.challenges];
  challenges.sort((a, b) => a.order - b.order);

  const initialEvaluationDone =
    skillProgress.initialEvaluation !== undefined &&
    skillProgress.initialEvaluation !== null;
  const finalEvaluationDone =
    skillProgress.finalEvaluation !== undefined &&
    skillProgress.finalEvaluation !== null;

  const isChallengeLocked = (index: number) => {
    const challenge = challenges[index];
    if (
      skillProgress.completedChallenges.includes(challenge.code) ||
      skillProgress.startedChallenges.includes(challenge.code)
    )
      return false;

    if (index === 0) {
      return !initialEvaluationDone;
    }

    const previousChallenge = challenges[index - 1];
    return !skillProgress.completedChallenges.includes(previousChallenge.code);
  };

  return (
    <AppContainer>
      <div className="skill-details-container">
        <BackButton onClick={onGoBack} />
        <motion.div variants={topToBottom} className="image">
          <img src={skill.imageUrl} alt="skill" />
        </motion.div>
        <div className="presentation">
          <motion.div variants={leftToRight} className="title">
            {skill.title}
          </motion.div>
          <motion.div
            variants={leftToRight}
            className="theme"
            style={{ color: skill.skillTheme.color }}
          >
            {skill.skillTheme.title}
          </motion.div>
        </div>
        <motion.div variants={topToBottom} className="metrics">
          <div className="metric">
            <div className="icon">
              <FiKey />
            </div>
            <div className="text">
              {skillProgress.numberOfCompletedChallenges}/
              {skillProgress.numberOfChallenges} défi(s) terminé(s)
            </div>
          </div>
          <div className="metric">
            <div className="icon">
              <FiClock />
            </div>
            <div className="text">
              {formatMinute(aggregateSkillDuration(skill))}
            </div>
          </div>
        </motion.div>
        <div className="skill-tab">
          <motion.div
            variants={leftToRight}
            className={`item ${activeTab === TAB.DETAILS ? "active" : ""}`}
            onClick={() => setActiveTab(TAB.DETAILS)}
          >
            À propos
          </motion.div>
          <motion.div
            variants={rightToLeft}
            className={`item ${activeTab === TAB.CHALLENGES ? "active" : ""}`}
            onClick={() => setActiveTab(TAB.CHALLENGES)}
          >
            Défis
          </motion.div>
        </div>
        <Render when={activeTab === TAB.DETAILS}>
          <Details
            shortDescription={skill.shortDescription}
            longDescription={skill.longDescription}
          />
        </Render>
        <Render when={activeTab === TAB.CHALLENGES}>
          <EvaluationCard
            completed={initialEvaluationDone}
            onClick={() => openEvaluation()}
          />
          <div className="challenges">
            {challenges.map((challenge, index) => (
              <ChallengeCard
                key={challenge.code}
                challenge={challenge}
                skillCode={skill.code}
                isLocked={isChallengeLocked(index)}
                isInProgress={skillProgress.startedChallenges.includes(
                  challenge.code
                )}
                isCompleted={skillProgress.completedChallenges.includes(
                  challenge.code
                )}
              />
            ))}
          </div>
          <EvaluationCard
            isFinal
            completed={finalEvaluationDone}
            isLocked={
              skillProgress.numberOfCompletedChallenges <
              skillProgress.numberOfChallenges
            }
            onClick={() => openEvaluation(true)}
          />
          <Render when={initialEvaluationDone && finalEvaluationDone}>
            <motion.div variants={bottomToTop} className="see-results">
              <div className="card" onClick={openResults}>
                <div className="label">
                  <div className="icon">
                    <FiActivity />
                  </div>
                  <div className="text">Voir les résultats</div>
                </div>
                <div className="icon">
                  <FiChevronRight />
                </div>
              </div>
            </motion.div>
          </Render>
        </Render>
      </div>
    </AppContainer>
  );
};

export default SkillDetails;

interface DetailsProps {
  shortDescription: string;
  longDescription: string;
}

const Details: React.FC<DetailsProps> = ({
  shortDescription,
  longDescription,
}) => {
  return (
    <motion.div variants={bottomToTop} className="details">
      <div className="info intro">
        <div className="title">Introduction</div>
        <div className="content">
          <MarkdownReader markdown={shortDescription} />
        </div>
      </div>
      <div className="info more">
        <div className="title">Détails</div>
        <div className="content">
          <MarkdownReader markdown={longDescription} />
        </div>
      </div>
    </motion.div>
  );
};

interface EvaluationCardProps {
  completed: boolean;
  isFinal?: boolean;
  isLocked?: boolean;
  onClick: () => void;
}

const EvaluationCard: React.FC<EvaluationCardProps> = ({
  completed,
  isFinal,
  isLocked,
  onClick,
}) => {
  const handleClick = () => {
    if (!isLocked) {
      onClick();
    }
  };

  return (
    <motion.div variants={leftToRight} className="evaluation-card">
      <div
        className={`card ${completed ? "completed" : ""} ${
          isLocked ? "locked" : ""
        }`}
        onClick={handleClick}
      >
        <div className="about">
          <div className="icon">
            <FiFlag />
          </div>
          <div className="text">
            <Render when={!isFinal}>Introduction</Render>
            <Render when={isFinal === true}>Bilan</Render>
          </div>
        </div>
        <div className="status">
          <Render when={!completed && !isLocked}>
            <FiChevronRight />
          </Render>
          <Render when={completed}>
            <FiCheckCircle />
          </Render>
          <Render when={isLocked === true}>
            <FiLock />
          </Render>
        </div>
      </div>
    </motion.div>
  );
};
