import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "./shared/services/routing/history";
import AuthRoutes from "./auth/AuthRoutes";
import { Provider } from "react-redux";
import store from "./store";
import HomeRoutes from "./home/HomeRoutes";
import { useEffect, useState } from "react";
import { authUser, storeAuthData } from "./auth/services/auth.service";
import { ApiOperationStatus } from "models/api-operation-result.model";
import { initializeUserSession } from "./user-session-initializer.action";
import { BrainLoader } from "./shared/components/Loader/Loader";
import { Wave } from "./shared/components/Decoration/Decoration";
import SkillRoutes from "./skill/SkillRoutes";
import ChallengeRoutes from "./challenge/ChallengeRoutes";
import ProfileRoutes from "./profile/ProfileRoutes";
import MiscRoutes from "./misc/MiscRoutes";
import ProgressRoutes from "./progress/ProgressRoutes";

const AppRoutes: React.FC = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);

    authUser()
      .then(async (result) => {
        if (result.status === ApiOperationStatus.Success && result.data) {
          storeAuthData(result.data);

          await store.dispatch(
            initializeUserSession({
              authenticationData: { token: result.data.accessToken },
              shouldRequestPushNotificationToken: false,
            })
          );
        } else {
          await store.dispatch(
            initializeUserSession({
              authenticationData: { token: undefined },
              shouldRequestPushNotificationToken: false,
            })
          );
        }
        setShowLoader(false);
      })
      .catch(async () => {
        await store.dispatch(
          initializeUserSession({
            authenticationData: { token: undefined },
            shouldRequestPushNotificationToken: false,
          })
        );
        setShowLoader(false);
      });
  }, []);

  if (showLoader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <BrainLoader />
        <Wave />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Switch>
        <Route path="/auth">
          <AuthRoutes />
        </Route>
        <Route path="/home">
          <HomeRoutes />
        </Route>
        <Route path="/skill">
          <SkillRoutes />
        </Route>
        <Route path="/challenge">
          <ChallengeRoutes />
        </Route>
        <Route path="/profile">
          <ProfileRoutes />
        </Route>
        <Route path="/privacy-policy">
          <MiscRoutes />
        </Route>
        <Route path="/progress">
          <ProgressRoutes />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    </Provider>
  );
};

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              <AppRoutes />
            </Switch>
          );
        }}
      />
    </Router>
  );
};

export default Routes;
